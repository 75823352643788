import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  filterRoot: {
    display: "inline-flex",
    width: "100%",
    marginLeft: theme.spacing(-2),
    spaceBetween: theme.spacing(1)
  },
  name: { minWidth: "150px" },
  accountNumber: {
    minWidth: "150px",
  },
  dateFilterContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  dateArrowContainer: {
    display: "block",
  },
  primaryChipContainer: {
    display: "inline-flex",
    margin: theme.spacing(2),
    "& div": {
      marginRight: theme.spacing(2),
      minWidth: "50px",
    },
  },
  clearFilterBtn: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(-3),
    float: "left"
  },
  clearFiltersContainer: {
    display: "inline-flex",
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1)
  },
  acctNumberSortGroup: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(3.5)
    },
    float: "left"
  },
  contractNameSortGroup: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(5)
    },
    float: "left",
  },
  contractNameContainer: {
    marginLeft: theme.spacing(1),
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    spacing: theme.spacing(0),
    padding: theme.spacing(1)
  },
  accountNumberContainer: {
    marginLeft: theme.spacing(1),
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    spacing: theme.spacing(0),
    padding: theme.spacing(1)
  }
}));
