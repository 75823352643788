import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  filterRoot: {
    display: "inline-flex",
    width: "100%",
  },
  credentialTypeDropdown: { minWidth: "150px" },
  activityTypeDropdown: {minWidth: "200px"},
  dateFilterContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  activityTypeContainer: {
    display: "inline-flex",
    marginLeft: theme.spacing(2),
    minWidth: "200px"
  },
  primaryChipContainer: {
    display: "inline-flex",
    margin: theme.spacing(2),
    "& div": {
      marginRight: theme.spacing(2),
      minWidth: "50px",
    },
  },
  clearFilterBtn: {
    margin: theme.spacing(2),
  },
}));
