import React, { useEffect } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "./styles";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const Dropdown = (props) => {
  const dropdownClasses = useStyles();
  const { name, options, handleChange, classes } = props;
  const [selectedItems, setSelectedItems] = React.useState([]);

  if (
    props.checkedItems != null &&
    props.checkedItems.length > 0 &&
    selectedItems.length <= 0
  )
    setSelectedItems(props.checkedItems);

  const handleSelect = (e) => {
    const selectedOptions = e.target.value;

    if (props.withSelectAll === true) {
      let selectedFromBefore = false;
      if (selectedItems.find((x) => x === "All")) {
        selectedFromBefore = true;
      }
      let selectedNow = false;
      if (selectedOptions.find((x) => x === "All")) {
        selectedNow = true;
      }
      if (selectedFromBefore && !selectedNow) {
        setSelectedItems([]);
        handleChange({ target: { name: name, value: [] } });
      } else if (!selectedFromBefore && selectedNow) {
        const chosenValuesForSelect = [];
        const chosenValuesForChange = [];
        chosenValuesForSelect.push("All");
        for (let i = 0; i < props.options.length; i++) {
          chosenValuesForSelect.push(props.options[i]);
          chosenValuesForChange.push(props.options[i]);
        }
        setSelectedItems(chosenValuesForSelect);
        handleChange({ target: { name: name, value: chosenValuesForChange } });
      } else if (selectedFromBefore && selectedNow) {
        const chosenValues = [];
        for (let i = 0; i < selectedOptions.length; i++) {
          if (selectedOptions[i] !== "All") {
            chosenValues.push(selectedOptions[i]);
          }
        }
        setSelectedItems(chosenValues);
        handleChange({ target: { name: name, value: chosenValues } });
      } else {
        const chosenValues = [];
        for (let i = 0; i < selectedOptions.length; i++) {
          chosenValues.push(selectedOptions[i]);
        }
        setSelectedItems(chosenValues);
        handleChange({ target: { name: name, value: chosenValues } });
      }
    } else {
      const chosenValues = [];
      for (let i = 0; i < selectedOptions.length; i++) {
        chosenValues.push(selectedOptions[i]);
      }
      setSelectedItems(chosenValues);
      handleChange({ target: { name: name, value: chosenValues } });
    }
  };

  const items = (props.withSelectAll === true && options.length > 0 ? ["All"].concat(options) : options).map((item, index) => {
    return (
      <MenuItem value={item} key={index}>
        <Checkbox checked={selectedItems.indexOf(item) > -1} color={props.checkboxColor} />
        {item}
      </MenuItem>
    );
  });

  return (
    <FormControl className={`${classes?.root} ${dropdownClasses?.root}`}>
      <TextField
        select
        label={name}
        value={selectedItems}
        inputProps={{
          multiple: true,
          renderValue: () => {
            return name;
          },
        }}
        variant="outlined"
        name={name}
        onChange={handleSelect}
        placeholder={name}
      >
        {items}
      </TextField>
    </FormControl>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  checkboxColor: PropTypes.string
};

Dropdown.defaultProps = {
  checkboxColor: "secondary"
}

export default Dropdown;
