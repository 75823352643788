import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { Grid, Paper } from "@material-ui/core";

const FPAAccountDetails = ({ acctDetails }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={clsx(classes.contentContainer)}>
      <Grid item xs={12} className={clsx(classes.mainWidth)} >
        <Paper elevation={4}>
          <Grid container spacing={2} className={clsx(classes.contentContainer, classes.paperContainer)}>
            <Grid item xs={12} className={clsx(classes.label)}>
              <div className={clsx(classes.label, classes.flexCenter, classes.spacing, "first-name")}>
                <span className={clsx(classes.paddingLeft, classes.accessHolder)}>{"".concat(acctDetails?.firstName, '  ', acctDetails?.lastName)}</span>
              </div>
            </Grid>
            <Grid item xs={4} className={clsx(classes.label, classes.noWrap)}>
              Account Low Threshold:
            </Grid>
            <Grid item xs={8} className={clsx(classes.textEnd, classes.value)}>
              $ {acctDetails?.lowThreshold ? acctDetails?.lowThreshold.toFixed(2) : "0.00"}
            </Grid>
            <Grid item xs={4} className={clsx(classes.label, classes.noWrap)}>
              Current Balance:
            </Grid>
            <Grid item xs={8} className={clsx(classes.textEnd, classes.value)}>
              $ {acctDetails?.balance ? acctDetails?.balance.toFixed(2) : "0.00"}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

FPAAccountDetails.defaultProps = {
  acctDetails: {
    balance: 0,
    email: "",
    firstName: "",
    lastName: "",
    lowThreshold: 0
  }
};

FPAAccountDetails.propTypes = {
  acctDetails: PropTypes.shape({
    balance: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lowThreshold: PropTypes.number
  })
};

export default FPAAccountDetails;
