import clsx from "clsx";
import React, { useState } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import FPAPaymentService from "../../services/FPAPaymentService";
import apiClient from "../../auth/apiClient";
const service = new FPAPaymentService(apiClient);

const FPAEmailReceiptForm = ({ accessHolderId, email, amiReferenceId }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [loading, setLoading] = useState(false);

  const sendReceipt = async () => {
    try {
      setLoading(true);
      const result = await service.postFPAEmailReceipt(
        accessHolderId, email, amiReferenceId
      );

      if (result?.status === 200) {
        enqueueSnackbar("Successfully sent receipt", { variant: "success" });
      } else {
        enqueueSnackbar("Receipt cannot be sent", {
          variant: "error", tag: "FailedToFPAEmailReceiptForm"
        });
      }
    } catch {
      enqueueSnackbar("Failed to Send Receipt", {
        variant: "error", tag: "FailedToFPAEmailReceiptForm"
      });
    } finally {
      setLoading(false);
      sessionStorage.removeItem('emailVerified');
      sessionStorage.removeItem('ahID');
    }
  };

  return (
    <>
      <Grid container spacing={2} className={clsx(classes.contentContainer, classes.mainWidth)}>
        <Grid item xs={12}>
          <Button
            disabled={loading}
            fullWidth
            data-id="submit-button"
            variant="contained"
            color="primary"
            name="submit"
            type="submit"
            className={clsx("submit-button", classes.buttonStyle)}
            onClick={sendReceipt}
          >
            Send Receipt
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

FPAEmailReceiptForm.defaultProps = {
  accessHolderId: "",
  email: "",
  amiReferenceId: ""
};

FPAEmailReceiptForm.propTypes = {
  accessHolderId: PropTypes.string,
  email: PropTypes.string,
  amiReferenceId: PropTypes.string
};

export default FPAEmailReceiptForm;