import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import clsx from "clsx";
import SquareChip from "../SquareChip";

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={props.classes}>
        {props.headerRow?.map((headCell) =>
				headCell.isButton
				? (
					<TableCell
						key={headCell.id}
						className={clsx(`${headCell.label.replace(/\s/g, "")}_tableCell`)}
						align="right"
						padding={headCell.disablePadding ? "none" : "default"}>
							<SquareChip
							className={clsx(`${headCell.label.replace(/\s/g, "")}_button`)}
							data-testid={headCell.label.replace(/\s/g, "")}
							text={headCell.label}
							onClick={headCell.onClick}
							mode="red"
							/>
					</TableCell>
				)
				:(
          <TableCell
            key={headCell.id}
            className={clsx(`${headCell.label.replace(/\s/g, "")}_tableCell`)}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              className={clsx(`${headCell.label.replace(/\s/g, "")}_label`)}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
