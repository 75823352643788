import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

 Severity: {
    //the order of these matter, as more critical will replace less
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
     minHeight: '6px !important', // override the default height set by MUI
     maxHeight: '6px !important', // override the default height set by MUI
    "&.low": {
      backgroundColor: theme.palette.warning.light, //limegreenyellow
    },
    "&.warning": {
      backgroundColor: theme.palette.warning.dark, //tan
    },
    "&.critical": {
      backgroundColor: theme.palette.secondary.dark, //red
    },
    "&.fire": {
      backgroundColor: "#FE626B", //brightred
    },
    "&.normal": {
      backgroundColor: "#1CBF2B", //green
    },
    "&.closed": {
      backgroundColor: theme.palette.amano.closed.main, //limegreenyellow
    },
    "&.offline": {
      backgroundColor: theme.palette.action.disabledBackground, // dark gray
    },
    "&.disabled": {
      backgroundColor: theme.palette.action.disabledBackground, // dark gray
    },
    "&.info": {
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));
