import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  versionContainer: {
    width: 200,
    position: "relative",
    "& > div.version-info": {
      position: "absolute",
      top: 55,
      left: 25,
      margin: "auto",
      textAlign: "center",
      backgroundColor: "rgba(164, 206, 248, 0.7)",
      border: "1px solid black",
      width: 150,
      "& > span.version-text": {
        opacity: 1.0,
        fontWeight: "bold",
      },
    },
  },
  versionSubTextGrid: {
    maxWidth: 200,
  },
  versionSubTextHeader: {
    float: "right",
    fontWeight: "bold",
  },
  versionSubText: {
    float: "right",
    marginTop: -5,
  },
  deviceSerialHeader: {
    float: "right",
    fontWeight: "bold",
  },
  deviceSerial: {
    float: "right",
    marginTop: -5,
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    borderBottomWidth: "0px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  border: {
    color: "black",
  },
}))(TableCell);
