import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  filterRoot: {
    display: "flex",
    width: "100%",
  },
  groupTypeContainer: {
    display: "inline-flex",
    marginLeft: theme.spacing(2),
    width: "250px",
  },
  groupTypeDropdown: {
    display: "inline-flex",
    width: "250px",
  },
  clearFilterBtn: {
    display: "inline-flex",
    margin: theme.spacing(2),
    width: "100px"
  }
}));