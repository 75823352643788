import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  alternatesContainer: {
    textAlign: "right",
    marginTop: 20,
  },
  alternateOption: {
    minWidth: 200,
    textalign: "left",
    marginBottom: "5px",
  },
  optionBox: {
    margin: 5,
  },

  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginTop: 5,
  },
  editorDrawer: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      flexShrink: 0,
    },    
  },
  // MUI typography elements use REMs, so you can scale the global
  // font size by setting the font-size on the <html> element.
  titlesubheading: {
    color: theme.palette.text.secondary,
    fontSize: 18,
    [theme.breakpoints.up("xs")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
    },
  },
  titleheading: {
    fontSize: 20,
    [theme.breakpoints.up("xs")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 26,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 28,
    },
  },
  fullWidthAccordion: {
    width: "100%",
  },
}));
