import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Zoom } from "@material-ui/core";

import { FindEntity } from "../../../state/slices/entities";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHubContext from "../../../hooks/useHubContext";
import { faLevelDownAlt } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useGate } from "../../../hooks/useGate";

const EntityGateButton = ({ entityID, confirmation, ...props }) => {
  const entity =
    useSelector(
      (state) => FindEntity(state.entities?.EntityList ?? [], entityID),
      shallowEqual
    ) ?? {};
  const enqueueSnackbar = useEnqueueSnackbar();
  const { Connected: PortalHubConnected } = useHubContext();
  const { isGateOpen, getGateActionAsString, sendGateAction } = useGate(entityID, true);

  const [gateOpen, setGateOpen] = useState(false);
  const [gateDisabled, setGateDisabled] = useState(false);

  const handleClick = async () => {
    if (confirmation) {
      if (!(await confirmation(props.mode !== "pulse" ? gateOpen : ""))) return;
    }

    const action = getGateActionAsString(props.mode, false);
    const userAction = getGateActionAsString(props.mode, true);
    await sendGateAction(action);
    enqueueSnackbar(`Command sent to ${entity.name} to ${userAction} the gate.`,
      {
        variant: "info",
        TransitionComponent: Zoom,
      }
    );
  };

  useEffect(() => {
    setGateOpen(isGateOpen());
  }, [isGateOpen]);

  useEffect(() => {
    //if portalhub isn't connected, can't send a command
    setGateDisabled(!PortalHubConnected);
    return () => {};
  }, [PortalHubConnected]);

  return (
    <Button
      disabled={entity.state === null ? true : gateDisabled}
      {...props}
      onClick={handleClick}
      startIcon={<FontAwesomeIcon
        className={clsx("btn-gate-icon")}
        icon={faLevelDownAlt}
        rotation={gateOpen ? 0 : 180}
        style={{ marginRight: '22px' }}
      />}
    >
      {getGateActionAsString(props.mode, true).toUpperCase()} GATE
    </Button>
  );
};

EntityGateButton.defaultProps = {
  entityID: null,
};
EntityGateButton.propTypes = {
  entityID: PropTypes.string,
  confirmation: PropTypes.func,
};

export default EntityGateButton;
