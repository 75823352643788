import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {},

    card: {
        padding:5,
        
        '&[data-tiled="true"]': {
            //height: "200px",
            
        },
        '&[data-tiled="false"]': {
            //height:"100px",    
        },
    },
    disabled: {
        backgroundColor: theme.palette.action.selected,
    }    
}));