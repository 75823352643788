import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import FPAPaymentService from "../../services/FPAPaymentService";
import apiClient from "../../auth/apiClient";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
const service = new FPAPaymentService(apiClient);

const FPAEmailValidationForm = ({ onSubmit, accessHolderId, userErrMessage }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const FPAEmailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
  });

  useEffect(() => {
    setErrMessage(userErrMessage);
  }, [userErrMessage]);

  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(FPAEmailValidationSchema),
    defaultValues: {
      email: ''
    }
  });

  async function handleFPAEmailSubmit(values) {
    try {
      setErrMessage(null);
      const result = await service.getFPAAccountDetails(accessHolderId, values.email);
      if (result?.status === 200 && result?.data != null) {
        setInvalidCredentials(false);
        result.data['email'] = values.email;
        onSubmit(result.data);
      } else {
        setInvalidCredentials(true);
        onSubmit(null);
        enqueueSnackbar("Cannot validate credentials", {
          variant: "error", tag: "FailedToGetDetails"
        });
      }
    } catch (err) {
      setInvalidCredentials(true);
      onSubmit(null);
      enqueueSnackbar("Failed to get details", {
        variant: "error", tag: "FailedToGetDetails"
      });
    }
  }

  return (
    <Grid container spacing={2} className={clsx(classes.contentContainer, classes.mainWidth, classes.flexCenter)}>
      {!invalidCredentials &&
        errMessage && (errMessage === "err" || errMessage === "cancel" || errMessage === "ok") && (
          <Grid item xs={12} className={clsx(classes.flexCenter)}>
            <Typography
              component="h2"
              name="invalid message"
              className={classes.invalid}
            >
              Please validate again to continue
            </Typography>
          </Grid>
        )}
      {invalidCredentials && (
        <Grid item xs={12} className={clsx(classes.flexCenter)}>
          <Typography
            component="h2"
            name="invalid message"
            className={classes.invalid}
          >
            Cannot Validate credentials
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={clsx(classes.flexCenter)}>
        <Controller
          name="email"
          control={control}
          render={({
            field
          }) => (
            <TextField
              {...field}
              variant="outlined"
              name="email"
              fullWidth
              id="email"
              type="email"
              label="Email Associated with Account"
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className={clsx(classes.flexCenter)}>
        <Button
          disabled={isSubmitting}
          fullWidth
          data-id="submit-button"
          variant="contained"
          color="primary"
          name="submit"
          type="submit"
          className={clsx(classes.receiptButton)}
          onClick={handleSubmit(async (data) => await handleFPAEmailSubmit(data))}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

FPAEmailValidationForm.defaultProps = {
  onSubmit: () => { },
  accessHolderId: "",
  userErrMessage: ""
};

FPAEmailValidationForm.propTypes = {
  onSubmit: PropTypes.func,
  accessHolderId: PropTypes.string,
  userErrMessage: PropTypes.string
};

export default FPAEmailValidationForm;