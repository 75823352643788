import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import PropTypes from "prop-types";
import { useStyles } from "./EntityStatusBar.style.js";
import _ from "lodash";

import {
  FindEntity,
  DetermineIssuesOnGivenEntity,
} from "../../../state/slices/entities";
import * as c from "../../../constants";
import clsx from "clsx";
//import clsx from "../../../../node_modules/clsx/clsx";

const EntityStatusBar = ({ entityID, disabled, isBeingListened }) => {
  const classes = useStyles();
  const entity =
    useSelector((state) => {
      const _entity = FindEntity(state.entities?.EntityList ?? [], entityID);
      return {
        entityid: _entity?.entityid ?? null,
        name: _entity?.name ?? null,
        type: _entity?.type ?? null,
        typeid: _entity?.typeid ?? null,
        state: _entity?.state ?? null,
        deviceMode:
          _.find(_entity?.settings ?? [], { name: "devicemode" })?.value ??
          null,
        closed:
          (_.find(_entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
          "true"
            ? true
            : _entity?.state?.Disabled) ?? false,
        reverseOffline: _entity?.state?.ReversingLane?.ReverseOffline, 
        rateid        : _entity?.details?.rateid    ?? null,
      };
    }, shallowEqual) ?? {};

  const [issueList, setIssueList] = useState([]);

  //  LOW: 0, WARNING: 50, CRITICAL: 80, WORLD_IS_ON_FIRE: 99,
  const determineSeverityClasses = () => {
    const arrayClasses = [];
    if (disabled) {
      arrayClasses.push("disabled");
      return arrayClasses;
    }

    if (_.find(issueList, { Severity: c.SEVERITY.WORLD_IS_ON_FIRE }))
      arrayClasses.push("fire");
    if (_.find(issueList, { Severity: c.SEVERITY.CRITICAL }))
      arrayClasses.push("critical");
    if (_.find(issueList, { Severity: c.SEVERITY.WARNING }))
      arrayClasses.push("warning");
    if (_.find(issueList, { Severity: c.SEVERITY.LOW }))
      arrayClasses.push("low");
    if (_.find(issueList, { Severity: c.SEVERITY.INFO }))
      arrayClasses.push("info");

    if (entity.closed === true || entity.reverseOffline === true) arrayClasses.push("closed");

    //finally, if all is good, class it as normal
    if (issueList.length === 0) arrayClasses.push("normal");

    return arrayClasses;
  };
  
  useEffect(() => {
    if (entity.typeid == c.ENTITY_TYPE.Device &&!isBeingListened){
      setIssueList([{ Type: "device", Severity: c.SEVERITY.INFO }]);
    }else{
      setIssueList(DetermineIssuesOnGivenEntity(entity)); //because we're calling this, we need to monitor state.
    }      
    return () => {};
  }, [ entity.state, entityID, isBeingListened, entity.deviceMode, entity.rateid]);

  //do math on the severities found in the list.
  //color bar accordingly
  if (entity.typeid != c.ENTITY_TYPE.Device) return <></>;
  return (
    <>
      <div
        className={clsx([
          classes.Severity,
          "severity-level",
          ...determineSeverityClasses(),
        ])}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          //border:"1px solid red",
          width: "100%",
          minHeight: 5,
          maxHeight: 5,
          height: 5,
        }}
        data-testid="entitystatus"
      />
    </>
  );
};

EntityStatusBar.defaultProps = { entityID: null, isBeingListened: true };
EntityStatusBar.propTypes = { entityID: PropTypes.string, isBeingListened: PropTypes.bool};

export default EntityStatusBar;
