import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import FPAAccountDetails from "./FPAAccountDetails";
import FPAAmountToAddForm from "./FPAAmountToAddForm";
import FPAEmailReceiptForm from "./FPAEmailReceiptForm";
import TermsAndConditions from "../Modals/TermsAndConditions";
import PropTypes from "prop-types";

import FPAPaymentService from "../../services/FPAPaymentService";
import apiClient from "../../auth/apiClient";
const service = new FPAPaymentService(apiClient);

const FPAPayment = ({ accessHolderId, resultsFromQuery, verifiedEmail }) => {
  const classes = useStyles();
  const [acctDetails, setAcctDetails] = useState();

  async function handleFPAEmailSubmit(email) {
    try {
      const result = await service.getFPAAccountDetails(accessHolderId, email);
      if (result?.status === 200 && result?.data != null) {
        result.data['email'] = email;      
        return result.data;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      const state = await handleFPAEmailSubmit(verifiedEmail);
      setAcctDetails(state);
    }
    fetchData();
  }, []);

  return (
    <div className={clsx(classes.muiGridRoot)}>
      {accessHolderId && (
        <Grid container spacing={2} className={clsx(classes.muiGridRoot)}>
          <Grid item xs={12} className={clsx(classes.flexCenter)}>
            <FPAAccountDetails acctDetails={acctDetails} />
          </Grid>

          {!resultsFromQuery?.amiReferenceId &&
            <Grid item xs={12} className={clsx(classes.flexCenter)}>
              <FPAAmountToAddForm accessHolderId={accessHolderId} />
            </Grid>
          }

          {resultsFromQuery?.amiReferenceId &&
            <Grid item xs={12} className={clsx(classes.flexCenter)}>
              <FPAEmailReceiptForm
                accessHolderId={accessHolderId}
                email={verifiedEmail}
                amiReferenceId={resultsFromQuery?.amiReferenceId}
              />
            </Grid>
          }

          <Grid item xs={12} className={clsx(classes.flexCenter)}>
            <div className={clsx(classes.termsAndConditions, "terms-and-conditions")}>
              <TermsAndConditions ticketId={null} />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
};

FPAPayment.defaultPropos = {
  accessHolderId: null,
  resultsFromQuery: {
    amiReferenceId: null,
    sessionId: null
  },
  verifiedEmail: null
};

FPAPayment.propTypes = {
  accessHolderId: PropTypes.string,
  resultsFromQuery: PropTypes.object,
  verifiedEmail: PropTypes.string
};

export default FPAPayment;