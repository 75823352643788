import { red } from "@material-ui/core/colors";
import { makeStyles, darken, lighten } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  querying: {
    backgroundColor: "silver",
    '&[data-state-warning="true"]': {
      // backgroundColor:theme.palette.warning.dark,
    },
    '&[data-state-error="true"]': {
      //backgroundColor:theme.palette.secondary.dark,
    },
  },
  armed: {
    backgroundColor: theme.palette.success.light,
  },
  closed: {
    //backgroundColor: theme.palette.action.disabledBackground,
  },
  closedIcon: {
    color: theme.palette.amano.closed.main,
    fontSize: 40,
    marginLeft: -8,
  },
  queried: {
    backgroundColor: theme.palette.amano.main,
    '&[data-state-error="true"]': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&[data-state-warning="true"]': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  iconQuerying: {
    //color: darken("rgb(198, 159, 159)",0.1),
    color: darken("#f0f0f0",0.4),
    fontSize: 42,
    fontWeight:"bold",
    opacity:0.54,
  },
  iconQueried: {
    display: "none",
  },
  iconDisabled: {
    color: theme.palette.amano.closed.main,
    fontSize: 40,
    marginLeft: -8,
  },
  small: {
    backgroundColor: theme.palette.amano.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  listeningIcon: {
    color: darken("#f0f0f0",0.4),
    fontSize: 20,
    fontWeight:"bold",
    opacity:0.54,
  },
  notListening: {
    color: theme.palette.amano.closed.main,
  },
}));
