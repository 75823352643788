import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    margin: theme.spacing(1)
  }
}));
