import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPrint             as PrinterIcon,
  faExclamationCircle as OthersIcon,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTabletAlt as DeviceIcon, faDollarSign as RateIcon } from "@fortawesome/pro-solid-svg-icons";
import {
  faMoneyBill  as BNRIcon,
  faCreditCard as CCIcon,
  faCarSide    as LoopsIcon,
} from "@fortawesome/pro-light-svg-icons";


import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useStyles } from "./EntityErrorMessages.styles";
import _ from "lodash";

import {
  FindEntity,
  DetermineIssuesOnGivenEntity,
} from "../../../state/slices/entities";
import * as c from "../../../constants";
import clsx from "clsx";
import { Popover, Typography } from "@material-ui/core";

const EntityErrorMessages = ({ entityID, isBeingListened }) => {
  const theme = useTheme();
  const classes = useStyles();

    const entity = useSelector((state) => {
      const _entity = FindEntity(state.entities?.EntityList ?? [], entityID);
      return {
        entityid    : _entity?.entityid ?? null,
        type        : _entity?.type     ?? null,
        typeid      : _entity?.typeid   ?? null,
        state       : _entity?.state    ?? null,
        deviceMode  : _.find(_entity?.settings ?? [], { name: "devicemode" })?.value ?? null,
        rateid        : _entity?.details?.rateid    ?? null,
      };
    }, shallowEqual) ?? {};    


  const [errorMessages, setErrorMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    //if entity.state changes
    if (entity.typeid == c.ENTITY_TYPE.Device ){
      if (!isBeingListened){
        setErrorMessages([{ Type: "device", Message: "Currently Not listening to Device", Severity: c.SEVERITY.INFO }]);
      }else{
        setErrorMessages(DetermineIssuesOnGivenEntity(entity));
      }
    } 
    return () => {};
  }, [ entity.state, entityID,isBeingListened, entity.rateid, entity.deviceMode]); //AO-3800, fixing so that error messages also updated on realtime

  return (
    <>
      <Typography
        variant="overline"
        style={{ color: theme.palette.warning.main }}
        component="div"
        data-testid="entity-err-msg"
      >
        {errorMessages.length == 1 && (
          <div
            className={clsx(
              errorMessages[0].Severity == c.SEVERITY.CRITICAL
                ? classes.critical
                : errorMessages[0].Severity == c.SEVERITY.LOW
                ? classes.low
                : errorMessages[0].Severity == c.SEVERITY.WARNING
                ? classes.warning
                : errorMessages[0].Severity == c.SEVERITY.INFO
                ? classes.info
                : classes.normal
            )}
          >
            <FontAwesomeIcon
              size="lg"
              style={{ marginRight: 4 }}
              fixedWidth
              icon={
                {
                  device      : DeviceIcon,
                  printer     : PrinterIcon,
                  bnr         : BNRIcon,
                  cc          : CCIcon,
                  gate        : OthersIcon,
                  armingloop  : LoopsIcon,
                  closingloop : LoopsIcon,                       
                  rate        : RateIcon,
                }[errorMessages[0].Type.toLowerCase()]
              }              
            />
            {errorMessages[0].Message}
          </div>
        )}
        {errorMessages.length > 1 && (
          <>
            <Typography
              variant="overline"
              style={{ color: theme.palette.warning.main, cursor: "help" }}
              component="div"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              Multiple Issues Reported
            </Typography>
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {errorMessages.map(function(msg, i) {
                return (
                  <div
                    key={i}
                    style={{ marginBottom: 2 }}
                    className={clsx(
                      msg.Severity == c.SEVERITY.CRITICAL
                        ? classes.critical
                        : msg.Severity == c.SEVERITY.LOW
                        ? classes.low
                        : msg.Severity == c.SEVERITY.WARNING
                        ? classes.warning
                        : classes.normal
                    )}
                  >
                    <FontAwesomeIcon
                      size="lg"
                      style={{ marginRight: 3 }}
                      fixedWidth
                      icon={
                        {
                          device      : DeviceIcon,
                          printer     : PrinterIcon,
                          bnr         : BNRIcon,
                          cc          : CCIcon,
                          gate        : OthersIcon,
                          armingloop  : LoopsIcon,
                          closingloop : LoopsIcon,
                          rate        : RateIcon,
                        }[msg.Type.toLowerCase()]
                      }
                    />
                    {msg.Message}
                  </div>
                );
              })}
            </Popover>
          </>
        )}
      </Typography>
    </>
  );
};

EntityErrorMessages.defaultProps = { entityID: null, isBeingListened: true};
EntityErrorMessages.propTypes = { entityID: PropTypes.string, isBeingListened: PropTypes.bool};

export default EntityErrorMessages;
