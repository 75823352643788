import React, { useState } from "react";
import { Button, Divider, Fade, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/pro-light-svg-icons";
import EntityGateButton from "../Entities/Modules/EntityGateButton";
import CycleDoorButton from "../Entities/Modules/CycleDoorButton";
import EntityRebootButton from "../Entities/Modules/EntityRebootButton";
import useHasPermissions from "../../hooks/useHasPermissions";
import { faRetweetAlt } from "@fortawesome/pro-duotone-svg-icons";

const DeviceActionMenu = ({ entityName, entityID, deviceMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useHasPermissions();
  const handleOpenActionMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionMenu = () => {
    setAnchorEl(null);
  };
  const RebootDevicePermission = hasPermissions(["overview.devices.reboot"], false, entityID);
  const OpenGatePermission = hasPermissions(["overview.opengates"], false, entityID);

  return (
    <>
      <Button
        onClick={handleOpenActionMenu}
        className={clsx(["actions-button"])}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<FontAwesomeIcon icon={faBolt} />}
        style={{
          marginRight: 20,
          lineHeight: 2,
          marginTop: 2,
          marginBottom: 4,
        }}
      >
        Actions
      </Button>
      <Menu
        className={clsx(["actions-menu"])}
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={Fade}
      >
        <Typography color="primary" style={{ padding: 6, paddingLeft: 14 }}>
          Actions
        </Typography>
        <Divider />
        {deviceMode?.toLowerCase() === "door" && <CycleDoorButton data-id="cycle-door-button" entityID={entityID} />}
        {deviceMode?.toLowerCase() !== "door" && OpenGatePermission && (
          <MenuItem>
            <EntityGateButton
              className={clsx(["btn-gate"])}
              data-name={entityName}
              size="small"
              entityID={entityID}
              color="primary"
            />
          </MenuItem>
        )}
        <MenuItem>
          {RebootDevicePermission && (
            <>
              <ListItemIcon style={{ marginRight: -15 }}>
                <FontAwesomeIcon className={clsx("reboot-device-icon")} icon={faRetweetAlt} swapOpacity />
              </ListItemIcon>
              <EntityRebootButton
                className={clsx(["btn-reboot-device"])}
                data-name={entityName}
                entityID={entityID}
                size="small"
                color="secondary"
              />
            </>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export default DeviceActionMenu;
