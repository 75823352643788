import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "inherit",
  },
  rootFormControl: {
    width: "inherit",
  },
  selectedChipContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectedChip: {
    margin: theme.spacing(0.5),
  },
}));
