import clsx from "clsx";
import React, {useState} from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { Grid, TextField, Button, InputAdornment, Paper } from "@material-ui/core";
import * as Yup from "yup";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import FPAPaymentService from "../../services/FPAPaymentService";
import apiClient from "../../auth/apiClient";
import AppConfig from "AppConfig";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'
import PlanetSelfSubmitForm from "../Forms/PlanetSelfSubmitForm";
import {PAYMENT_GATEWAY} from "../../constants/index";
const service = new FPAPaymentService(apiClient);

const FPAAmountToAddForm = ({ accessHolderId }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentLocation = window.location;
  const [tokenRequestResponse, setTokenRequestResponse] = React.useState({});
  const [hostBase, setHostBase] = useState('');
  const port = window.location.port ? ":" + window.location.port : "";

  const FPAAmountToAddSchema = Yup.object().shape({
    amountToAdd: Yup.number()
      .typeError("Must be a numeric value")
      .min(0.01, "Must be positive")
      .required("Required")
  });

  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(FPAAmountToAddSchema),
    defaultValues: {
      amountToAdd: 0
    }
  });

  const isValidHttpUrl = (val) => {
    let url;
    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  async function handlePaymentSubmit(values) {
    try {
      const result = await service.postFPARequestPayment(
        accessHolderId,
        `${currentLocation.protocol}//${currentLocation.hostname}${port}`,
        values.amountToAdd,
        `${AppConfig.externalServices.baseURL}`,
      );

      if(result.data?.gateway === PAYMENT_GATEWAY.WINDCAVE) {
        const redirectUri = result?.data?.redirectUri;
        if(isValidHttpUrl(redirectUri)){
          window.location.replace(redirectUri);
        }else {
          enqueueSnackbar("Payment was not Processed", {
            variant: "error", tag: "FailedToAddPaymentToFPA"
          });
        }
      } else if(result.data?.gateway === PAYMENT_GATEWAY.PLANET) {
        setHostBase(`${currentLocation.protocol}//${currentLocation.hostname}${port}/fpapay/${accessHolderId}`);
        setTokenRequestResponse(result.data);
      } else {
        enqueueSnackbar("Payment was not Processed", {
          variant: "error", tag: "FailedToAddPaymentToFPA"
        });
      }
    } catch {
      enqueueSnackbar("Failed to Add Payment", {
        variant: "error", tag: "FailedToAddPaymentToFPA"
      });
    }
  }

  return (
    <>
    <Grid container spacing={2} className={clsx(classes.contentContainer, classes.mainWidth, classes.spaceOnTop)}>
      <Grid item xs={12} className={clsx(classes.mainWidth)} >
        <Grid item xs={4} className={clsx(classes.mainWidth)} >
          <Paper elevation={4} >
            <Grid container spacing={2} className={clsx(classes.contentContainer, classes.paperContainer)}>
              <Grid item xs={4} className={clsx(classes.label, classes.noWrap)}>
                Amount to Add:
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="amountToAdd"
                  control={control}
                  render={({
                    field
                  }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      name="amountToAdd"
                      fullWidth
                      id="amountToAdd"
                      type="text"
                      error={!!errors}
                      helperText={errors.amountToAdd && errors.amountToAdd.message}
                      required
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.01,
                          "data-testid": "fpa-accessholder-rechargeamount",
                          style: { textAlign: "right" },
                        },
                        inputMode: "numeric",
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      data-id="amountToAdd-text"
                      data-testid="amountToAdd-text"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={8} className={clsx(classes.mainWidth, classes.spaceOnTop)} >
          <Button
            disabled={isSubmitting}
            fullWidth
            data-id="submit-button"
            data-testid="submit-button"
            variant="contained"
            color="primary"
            name="submit"
            type="submit"
            className={clsx("submit-button", classes.buttonStyle)}
            onClick={handleSubmit(async (data) => await handlePaymentSubmit(data))}
          >
            Add Funds
          </Button>
        </Grid>
      </Grid>
    </Grid>
    {/* Adding form for redirection to planet payment page once user clicks on ADD button */}
    {tokenRequestResponse.gateway === PAYMENT_GATEWAY.PLANET &&  
      (
        <PlanetSelfSubmitForm 
          planetRequestResponse={tokenRequestResponse}
          hostBase={hostBase}
        />
    )}
    </>
  );
};

FPAAmountToAddForm.defaultProps = {
  accessHolderId: ""
};

FPAAmountToAddForm.propTypes = {
  accessHolderId: PropTypes.string
};

export default FPAAmountToAddForm;