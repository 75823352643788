import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  facilityDivider: {
    height: "2.75em",
    fontWeight: "bold",
    color: theme.palette.grey[500]
  },

  facilityDividerSpacing: {
    marginTop: "0.5em"
  },

  facilityDevice: {
    paddingLeft: "2em"
  },

  selectLabel: {
    marginTop: "-0.5em"
  },

  select: {
    minWidth: "120px",
    padding: "8px 10px",
    textAlign: "left",
    backgroundColor: theme.palette.background.default,
  },
}));
