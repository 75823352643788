import { red } from "@material-ui/core/colors";
import { makeStyles, lighten, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  iconBar: {
    marginTop: "15px",
    textAlign: "center",
  },
  querying: {
    opacity: 0.3,
  },
  queried: {},

  iconBarIcon: {
    color: theme.palette.amano.main,
    fontSize: "16pt",
    marginLeft: "10px",
    marginRight: "5px",
    "&.offline": { color: theme.palette.text.disabled },
    "&.closed": { color: theme.palette.secondary.main },

    "&.active": { color: theme.palette.amano.active.light },
    "&.loop-on": { color: theme.palette.amano.active.light },
    "&.loop-off": { color: theme.palette.amano.main },
    "&.closingloop": { color: theme.palette.amano.active.main },
    "&.error": { color: darken(theme.palette.error.dark, 0.2) },

    "&.low": { color: theme.palette.amano.warnings.low },
    "&.warning": { color: theme.palette.warning.light },
    "&.critical": { color: darken(theme.palette.error.dark, 0.2) },
    "&.fire": { color: theme.palette.amano.warnings.fire },

    "&.disabled": { color: theme.palette.text.disabled },
  },

  car: {
    //props for car, front and rear wheel
    marginLeft: 5,
    marginRight: 10,
    marginBottom: 2,
  },
  wheel: {
    fontSize: 5.9,
    color: "rgba(0,0,0,0)",
    "&.arming": {
      "&.on": { color: "#a4c99d" },
      "&.off": {},
      "&.unknown": { color: lighten(theme.palette.warning.light, 0.5) },
    },
    "&.closing": {
      "&.on": { color: "#a4c99d" },
      "&.off": {},
      "&.unknown": { color: lighten(theme.palette.warning.light, 0.5) },
    },
  },

  deviceTile: {
    position: "absolute",
    bottom: 5,
    right: 5,
    border: "1px solid",
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    padding: "2px",
    paddingLeft: 4,
    paddingRight: 4,
  },

  featureTile: {
    position: "absolute",
    bottom: 5,
    left: 5,
    border: "1px solid",
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    padding: "2px",
    paddingLeft: 4,
    paddingRight: 4,
  },

  featureTileTooltip: {
    cursor: "help",
  },
}));

export default useStyles;
