import React, { useCallback, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

import { FindEntity } from "../../../state/slices/entities";

import { useStyles } from "./ContextList.style";
import ContextCard from "./ContextCard"
import clsx from "clsx";
import * as c from "../../../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const EntityList = ({ props }) => {
  const classes = useStyles();
  const facilityID = useSelector((state) => state.entities?.ContextID);
  const [tiled, setTiled] = useState(true);

  const { contractNest } = useFlags();
  const contractNestFeature = useFeatureFlag("Contract Nest");
  const isEnabledByEntityType = useCallback((typeID) => {
    switch (typeID) {
      case c.ENTITY_TYPE.Area:
        return contractNestFeature && contractNest;
    }
    return true;
  }, [contractNestFeature, contractNest]);

  const currentEntity = useSelector((state) => {
    const device = FindEntity(
      state.entities?.EntityList ?? [],
      facilityID
    );

    let newDeviceChildren = [];    
    for (const child of device?.children) {
      if (isEnabledByEntityType(c.ENTITY_TYPE.Area) && child.typeid == c.ENTITY_TYPE.Area) {
        const deviceUnderArea = FindEntity(
          state.entities?.EntityList ?? [],
          child.entityid
        );
        if (deviceUnderArea?.children != null)
          newDeviceChildren = newDeviceChildren.concat(deviceUnderArea?.children);
      } else {
        newDeviceChildren.push(child);
      }      
    }
    return {
      parententityid: device?.parententityid,
      name: device?.name,
      entityid: device?.entityid,
      children: _.orderBy(newDeviceChildren, ["name"], ["asc"])
    };
  }, shallowEqual);

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      marginRight: 5,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,

        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  return (
    <>
      <Paper elevation={1} style={{ padding: "1.5em", width: "100%" }}>
        <Grid container direction="row" spacing={2} alignItems="flex-start">

          <Grid item className={classes.cardContainer} spacing={2}>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justify="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Paper elevation={1} spacing={3} style={{ paddingLeft: 10 }}>
                  <Typography variant="h5" color="primary">
                    {currentEntity.name.toUpperCase()}
                    <span style={{ float: "right" }}>
                      <FormControlLabel
                        className={clsx("tiled-toggle")}
                        control={
                          <AntSwitch
                            checked={tiled}
                            color="primary"
                            onChange={() => {
                              setTiled(!tiled);
                            }}
                          />
                        }
                        label="Tile"
                      />
                    </span>
                  </Typography>
                </Paper>
              </Grid>

              {currentEntity != null &&
                currentEntity?.children?.map((x, index) => (
                  isEnabledByEntityType(x.typeid) && (
                    <Grid
                      key={"grid" + index}
                      item
                      xl={tiled ? 2 : 12}
                      lg={tiled ? 3 : 12}
                      md={tiled ? 6 : 12}
                      sm={tiled ? 12 : 12}
                      xs={tiled ? 12 : 12}
                    >
                      <ContextCard
                        key={"cardc" + index}
                        entityID={x.entityid}
                        tiled={tiled}
                      />
                    </Grid>
                  )
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default EntityList;
