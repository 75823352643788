import React, { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Grow from "@material-ui/core/Grow";
import { RibbonContainer, RightCornerRibbon } from "react-ribbons";
import PropTypes from "prop-types";
import { useStyles } from "./ContextCard.style";
import clsx from "clsx";
import _ from "lodash";
import { FindEntity } from "../../../state/slices/entities";
import * as c from "../../../constants";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import EntityToiletSwirl from "../Modules/ToiletSwirl";
import EntityIconBar from "../Modules/EntityIconBar";
import OccupancyMiniPanel from "../../Occupancy/MiniPanel";

import EntityExpandedView from "../../EntityCard/Expanded/index.js";
import EntityProvisionButton from "../Modules/EntityProvisionButton";
import useHasPermissions from "../../../hooks/useHasPermissions";
import EntityStatusBar from "../Modules/EntityStatusBar";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import EntityDeleteButton from "../Modules/EntityDeleteButton.js";
import DeviceActionMenu from "../../EntityCard/DeviceActionMenu.js";

const DetailTransition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

const ContextCard = ({ entityID, tiled }) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const provisionDevicePermission = hasPermissions([
    "overview.devices.provisiondevice",
  ]);
  const cardState = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      laneClosed:
        _.find(entity?.settings, { name: "laneclosedsign" })?.value == "true",
      disabled: entity.state != null && entity?.state?.Disabled == true,
      lotFull: entity.state != null && entity?.state?.Occupancy?.Full == true,
      entityType: entity.typeid,
      deviceMode:
        _.find(entity?.settings ?? [], { name: "devicemode" })?.value ?? null,
    };
  }, shallowEqual);

  const [showSettings, setShowSettings] = React.useState(false);

  const closeExpanded = () => {
    setShowSettings(false);
  };
  const openExpanded = () => {
    if (isDisabledByFeature()) return;
    setShowSettings(true);
  };

  const hotelIntegrationsName = "Hotel Integrations";
  const hotelIntegrations = useFeatureFlag(hotelIntegrationsName);
  const isDisabledByFeature = useCallback(() => {
    switch (cardState.deviceMode) {
      case c.DEVICE_MODE.HOTEL_INTERFACE:
        return !hotelIntegrations;
    }
    return false;
  }, [cardState.deviceMode, hotelIntegrations]);

  const getDisabledFeatureName = useCallback(() => {
    switch (cardState.deviceMode) {
      case c.DEVICE_MODE.HOTEL_INTERFACE:
        return hotelIntegrations ? "" : hotelIntegrationsName;
    }
    return "";
  }, [cardState.deviceMode, hotelIntegrations]);

  return (
    <>
      <div className={classes.root}>
        <RibbonContainer className="custom-ribbon-class">
          {cardState.disabled && (
            <RightCornerRibbon
              backgroundColor="#854545"
              color="#fff"
              fontFamily="Arial"
            >
              DISABLED
            </RightCornerRibbon>
          )}
          {cardState.laneClosed && (
            <RightCornerRibbon
              backgroundColor="rgba(0,0,0,0.74)"
              color="#fafafa"
              fontFamily="Arial"
            >
              CLOSED
            </RightCornerRibbon>
          )}
          {cardState.lotFull && (
            <RightCornerRibbon
              backgroundColor="rgba(0,0,0,0.74)"
              color="#fafafa"
              fontFamily="Arial"
            >
              LOT FULL
            </RightCornerRibbon>
          )}
          <Card
            className={clsx(
              classes.card,
              cardState.entityType == c.ENTITY_TYPE.Device &&
                (cardState.disabled || isDisabledByFeature())
                ? classes.disabled
                : ""
            )}
            onClick={openExpanded}
            data-tiled={tiled.toString()}
            data-id={entityID}
            data-testid="ctx-device-card"
          >
            <EntityStatusBar
              entityID={entityID}
              disabled={isDisabledByFeature()}
            />
            <EntityToiletSwirl
              entityID={entityID}
              disableTitle={false}
              iconSize="1x"
              disabled={isDisabledByFeature()}
            />

            <CardContent style={{ marginTop: -10, marginBottom: -10 }}>
              <Typography variant="body2" color="textSecondary" component="p">
                {cardState.entityType == c.ENTITY_TYPE.Facility && (
                  <OccupancyMiniPanel entityId={entityID} />
                )}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <EntityIconBar
                entityID={entityID}
                disabledFeature={getDisabledFeatureName()}
              />
              <div style={{ position: "absolute", top: 10, right: 10 }}></div>
            </CardActions>
          </Card>
        </RibbonContainer>
      </div>

      {/* Detail Blowout */}
      <Dialog
        fullScreen={true}
        TransitionComponent={DetailTransition}
        scroll="paper"
        open={showSettings}
      >
        <DialogContent dividers={true}>
          <EntityExpandedView entityId={entityID} />
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          {cardState.entityType == c.ENTITY_TYPE.Device && (
            <>
              {provisionDevicePermission && (
                <EntityProvisionButton
                  entityID={entityID}
                  className={clsx(["btn-provision"])}
                  data-name=""
                />
              )}
              <EntityDeleteButton
                className={clsx(["btn-delete-entity"])}
                data-testid="ctx-card-del-btn"
                entityID={entityID}
                variant="outlined"
                size="small"
                color="secondary"
              />
              <div style={{ flex: "1 0 0" }} />
              <DeviceActionMenu
                entityID={entityID}
                entityName={entityID}
                deviceMode={cardState.deviceMode}
              />
              <Typography>|</Typography>
            </>
          )}

          <Button
            onClick={closeExpanded}
            color="primary"
            className={clsx(["btn-close-detail"])}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ContextCard.defaultProps = { entityID: null, tiled: true };
ContextCard.propTypes = { entityID: PropTypes.string, tiled: PropTypes.bool };

export default React.memo(ContextCard);
