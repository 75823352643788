import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "5px",
    height: "inherit",
  },
  select: {
    height: "inherit",
  },
  label: { paddingLeft: "10px" },
}));
