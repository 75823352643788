import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* MUI Imports */
import {
  useTheme,
  Box,
  MenuItem,
  FormControl,
  Chip,
  InputLabel,
  Select,
  OutlinedInput,
} from "@material-ui/core";

/* Style */
import { useStyles } from "./styles";
import clsx from "clsx";

/* Utilities */
import * as _ from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

function getStyles(id, chips, theme) {
  const selected = !_.isUndefined(chips.find((x) => x.id === id));
  return {
    fontWeight: selected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
    background: selected ? theme.palette.selectedItem?.main : undefined,
  };
}

export default function ChipMultiSelect({
  identifier,
  className,
  options,
  label,
  onChange,
  selected,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [chips, setChips] = useState([]);

  useEffect(() => {
    if (selected) setChips(selected);
  }, [JSON.stringify(selected)]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const addedWithSelection = value.length > chips.length;
    let newChips = [...chips];
    let chipsIDs = chips.map((x) => x.id);
    if(addedWithSelection){
      const id = value.filter((x) => !chipsIDs.includes(x))[0];
      let newIndex = findOptionObject(id)
      newChips = [...newChips, newIndex]
    } else{
      const index = chipsIDs.findIndex((x) => !value.includes(x));
      newChips.splice(index, 1);        
    }   

    setChips(newChips);
    onChange(newChips);   
  };

  const findOptionObject = (id) => {
    const option = options?.find((opt) => opt.id === id)
    return option;
  };

  const findChipValue = (id) => {
    return options?.find((opt) => opt.id === id)?.value;
  };

  return (
    <div className={clsx(className, classes.root)}>
      <FormControl className={classes.rootFormControl} variant="outlined">
        <InputLabel id="multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          className={identifier}
          data-testid={identifier}
          multiple
          value={chips?.map((chip) => chip.id)}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box className={classes.selectedChipContainer}>
              {selected.map((item) => (
                <Chip
                  className={clsx(`chip-${item}`, classes.selectedChip)}
                  key={item}
                  label={findChipValue(item)}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((item) => (
            <MenuItem
              className={`multi-select-option-${item.id}`}
              key={item.id}
              value={item.id}
              style={getStyles(item.id, chips, theme)}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

ChipMultiSelect.defaultProps = {
  identifier: "chip-multi-textfield",
  options: [],
  onChange: () => {},
  selected: [],
};

ChipMultiSelect.propTypes = {
  identifier: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.any,
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.any,
    })
  ),
  onChange: PropTypes.func,
};
