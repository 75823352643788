import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Box, Button, Typography } from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./EntityQRCode.style";
import PropTypes from "prop-types";
import _ from "lodash";
import CancelIcon from "@material-ui/icons/Cancel";

import { FindEntity, FindNearestFacilityFromEntity } from "../../../state/slices/entities";

import QRCode from "react-qr-code";
import apiClient from "../../../auth/apiClient";
import AuthService from "../../../services/AuthService";

const EntityQRCode = ({ entityID, onClose }) => {
  const classes = useStyles();
  const entity = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    const facility = FindNearestFacilityFromEntity(state.entities?.EntityList ?? [], entityID);
    return {
      parententityid: facility.entityid,
      entityid: entity.entityid,
      name: entity.name,
    };
  }, shallowEqual);
  const authService = new AuthService(apiClient);

  const [qrCodeData, setQRCodeData] = useState("");

  const LoadQRData = () => {
    authService
      .getClientIdAndSecret(entity.parententityid, entity.entityid)
      .then((result) => {
        setQRCodeData(
          `{"DeviceID": "${result.data.deviceID}", "ClientID": "${result.data.clientID}", "ClientSecret": "${result.data.clientSecret}", "CurrentFacility": "${result.data.facilityID}"}`
        );
      })
      .catch((err) => {
        setQRCodeData("");
      });
  };

  useEffect(() => {
    //if user or entity changes
    LoadQRData();
    return () => {};
  }, [entityID]);

  return (
    <div className={classes.qrCode}>
      <Typography color="primary" style={{ marginBottom: 20 }} variant="h6">
        Show QR Code to
        <Typography variant="h5" component="h5" color="secondary">
          {entity.name.toUpperCase()}
        </Typography>
        to Register The Device
      </Typography>
      {qrCodeData === "" ? (
        <Box
          sx={{
            width: 256,
            height: 256,
            bgColor: "primary.main",
            opacity: 0.5,
          }}
        />
      ) : (
        <QRCode value={qrCodeData} />
      )}
      <div />
      <Typography style={{ marginTop: 30 }}>
        <Button
          startIcon={<CancelIcon />}
          variant="contained"
          onClick={onClose}
        >
          CLOSE
        </Button>
      </Typography>
    </div>
  );
};

EntityQRCode.defaultProps = { entityID: null };
EntityQRCode.propTypes = { entityID: PropTypes.string };

export default EntityQRCode;
