import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {},

    qrInstruction: {
      fontSize: 18,
      textAlign: "center",
      marginBottom: "20px",
    },
    qrDeviceName: {
      fontSize: 18,
      textAlign: "center",
      marginTop: "20px",
    },
    qrCode: {
      margin:"0 auto",
      textAlign:"center",
      width:"100%",
    },
    rootPaper: {
      maxWidth: "50vw",
    },
    qrFooter: {
      fontSize: 12,
      textAlign: "center",
      marginTop: "25px",
    },

}));

