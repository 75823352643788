import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { Drawer, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { Divider, Typography, Button, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import * as c from "../../../../constants";
import _ from "lodash";

import { FindEntity } from "../../../../state/slices/entities";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import SettingsForm from "../../../Forms/Settings";

import GenericEditor from "../../../Forms/Onboarding/DeviceEditor";
import FacilityEditor from "../../../Forms/Onboarding/FacilityEditor";
import AreaEditor from "../../../Forms/Onboarding/AreaEditor";
import ValetAreaEditor from "../../../Forms/Onboarding/ValetAreaEditor";
import clsx from "clsx";
import { fetchPeripheralsList } from "../../../../state/slices/peripherals";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import FacilityGroupEditor from "../../../Forms/Onboarding/FacilityGroupEditor";
import { FlagFormContextProvider } from "../../../Forms/Features/EnableFeatureFlags/flagformcontextprovider";

export const EntityDetailSettings = ({ entityId }) => {

  function castBool(setting) {
    if (!setting) return false;
    if (typeof setting.value === "string")
      return setting.value === "true" ?? false;
    if (typeof setting.value === "boolean") return setting.value;
    return false;
  }

  const classes = useStyles();

  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const currentUser = useCurrentUser();
  const { hasPermissions } = useHasPermissions();

  const [showEditDrawer, setShowEditDrawer] = useState(false);

  const entity = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityId);
    if (!entity) return {};
    return {
      entityid: entity.entityid,
      parententityid: entity.parententityid,
      typeid: entity.typeid,
      type: entity.type,
      name: entity.name,
      description: entity.details?.description,
    };
  }, shallowEqual);

  const extractCommonSettings = (entity) => {
    return {
      displaylotfull: _.find(entity?.settings ?? [], { name: "displaylotfull" })
        ?.value ?? "",
      backouttimeout:
        _.find(entity?.settings ?? [], { name: "backouttimeout" })?.value ?? 0,
      autoprintreceipt:
        _.find(entity?.settings ?? [], { name: "autoprintreceipt" })?.value ==
        "true",
      ticket_footer:
        _.find(entity?.settings ?? [], { name: "ticket.footer" })?.value ?? "",
      ticket_header:
        _.find(entity?.settings ?? [], { name: "ticket.header" })?.value ?? "",
      receipt_footer:
        _.find(entity?.settings ?? [], { name: "receipt.footer" })?.value ?? "",
      receipt_header:
        _.find(entity?.settings ?? [], { name: "receipt.header" })?.value ?? "",
      toidletimeout:
        _.find(entity?.settings ?? [], { name: "toidletimeout" })?.value ?? 0,
      centralpaytrxtimeout:
        _.find(entity?.settings ?? [], { name: "centralpaytrxtimeout" })
          ?.value ?? 0,
      thirdpartyaccessgroup:
        _.find(entity?.settings ?? [], { name: "thirdpartyaccessgroup" })
          ?.value ?? 0,
      thirdpartyrate:
        _.find(entity?.settings ?? [], { name: "thirdpartyrate" })?.value ?? 0,
      issueticketwhilefull:
        _.find(entity?.settings ?? [], { name: "issueticketwhilefull" })
          ?.value == "true",
      duplicatecredentialreaddelay:
        _.find(entity?.settings ?? [], { name: "duplicatecredentialreaddelay" })
          ?.value ?? 0,
      hotelinterface:
        _.find(entity?.settings ?? [], { name: "hotelinterface" })?.value ??
        "{}",
      intercomprovider:
        _.find(entity?.settings ?? [], { name: "intercomprovider" })?.value ??
        1,
      poeEnabled:
        _.find(entity?.settings ?? [], { name: "poe.enabled" })?.value ===
        "true" ?? false,
      poeAttendedMode:
        _.find(entity?.settings ?? [], { name: "poe.attendedmode" })?.value ===
        "true" ?? false,
      poeShowTicketButton:
        _.find(entity?.settings ?? [], { name: "poe.showticketbutton" })
          ?.value === "true" ?? false,
      poeOffers:
        _.find(entity?.settings ?? [], { name: "poeoffers" })?.value ?? [],
      lprConfidenceScore:
        _.find(entity?.settings ?? [], { name: "lpr.confidencescore" })
          ?.value ?? 0,
      lprMatchAccuracy:
        _.find(entity?.settings ?? [], { name: "lpr.matchaccuracy" })?.value ??
        0,
      lprCredential:
        _.find(entity?.settings ?? [], { name: "lpr.credential" })?.value ===
        "true" ?? false,
      lprCalculateFee:
        _.find(entity?.settings ?? [], { name: "lpr.calculatefee" })?.value ===
        "true" ?? false,
      lprHotelGuestAutoEnroll:
        _.find(entity?.settings ?? [], { name: "lpr.hotelguestautoenroll" })?.value ===
        "true" ?? false,
      lprTicketless:
        _.find(entity?.settings ?? [], { name: "lpr.ticketless" })?.value ===
        "true" ?? false,
       lprTicketLessExceptionFeeDelay:
        _.find(entity?.settings ?? [], { name: "lpr.ticketlessexceptionfeedelay" })
          ?.value ?? 0,
      lprTicketLessExceptionFeeDuration:
        _.find(entity?.settings ?? [], { name: "lpr.ticketlessexceptionfeeduration" })
          ?.value ?? 0,
      lprTicketlessEntryCaptureMode:
        _.find(entity?.settings ?? [], { name: "lpr.ticketlessentrycapturemode" })
          ?.value ?? 1,
      continuousCallCenterNotification: castBool(
        _.find(entity?.settings ?? [], { name: "intercom.continuousring" })
      ),
      ticketmasterurl:
        _.find(entity?.settings ?? [], { name: "ticketmasterurl" })?.value ?? "",
      ticketmastertoken:
        _.find(entity?.settings ?? [], { name: "ticketmastertoken" })?.value ?? "",
      ticketmastervenueid:
        _.find(entity?.settings ?? [], { name: "ticketmastervenueid" })?.value ?? "",
      ticketmastergatename:
        _.find(entity?.settings ?? [], { name: "ticketmastergatename" })?.value ?? "",
      onlinevalidationlimitperticketenabled:
        _.find(entity?.settings ?? [], { name: "onlinevalidationlimitperticketenabled" })
          ?.value == "true" ?? false,
      onlinevalidationlimitperticket:
        _.find(entity?.settings ?? [], { name: "onlinevalidationlimitperticket" })
          ?.value ?? 1,
      allowtransactionswhilegateraised:
        _.find(entity?.settings ?? [], { name: "allowtransactionswhilegateraised" })
          ?.value == "true" ?? false,
    };
  };

  const extractTypeSpecificSettings = (entity) => {
    switch (entity.typeid) {
      case c.ENTITY_TYPE.Device:
        return {
          devicemode: _.find(entity?.settings ?? [], { name: "devicemode" })
            ?.value,
          devicedisabled:
            _.find(entity?.settings ?? [], { name: "devicedisabled" })?.value ==
            "true" ?? false,
          laneclosedsign:
            _.find(entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
            "true",
          ccdeviceid: _.find(entity?.settings ?? [], { name: "ccdeviceid" })
            ?.value,
          parker_user: _.find(entity?.settings ?? [], { name: "parker.user" })
            ?.value,
          parker_password: _.find(entity?.settings ?? [], {
            name: "parker.password",
          })?.value,
          valetdeviceid: _.find(entity?.settings ?? [], {
            name: "valetdeviceid",
          })?.value,
          ticketmastermacaddress:
            _.find(entity?.settings ?? [], { name: "ticketmastermacaddress" })?.value ?? "",
          ticketmasterdeviceid:
            _.find(entity?.settings ?? [], { name: "ticketmasterdeviceid" })?.value ?? "",
                  };
      case c.ENTITY_TYPE.Facility:
        return {
          ccreplyto:
            _.find(entity?.settings ?? [], { name: "ccreplyto" })?.value ==
            "true",
          emailreply:
            _.find(entity?.settings ?? [], { name: "emailreply" })?.value ?? "",
          emailfrom:
            _.find(entity?.settings ?? [], { name: "emailfrom" })?.value ?? "",
          emaildisplayfrom:
            _.find(entity?.settings ?? [], { name: "emaildisplayfrom" })?.value ?? "",
          emailtemplate:
            _.find(entity?.settings ?? [], { name: "emailtemplate" })?.value ??
            "",
          shortchangeenabled:
            _.find(entity.settings, { name: "shortchangeenabled" })?.value ===
            "true" ?? false,
          shortchangeinstructions:
            _.find(entity.settings, { name: "shortchangeinstructions" })
              ?.value ??
            "We are sorry for the inconvenience! This machine did not have enough cash to give you the correct change. Please contact the parking office to collect the remaining change shown above.",
          receipt_footer:
            _.find(entity?.settings ?? [], { name: "receipt.footer" })?.value ??
            "",
          receipt_header:
            _.find(entity?.settings ?? [], { name: "receipt.header" })?.value ??
            "",
          lostticketallowvalidations:
            _.find(entity?.settings ?? [], {
              name: "lostticketallowvalidations",
            })?.value == "true",
          lprImageRetention:
            _.find(entity?.settings ?? [], { name: "lpr.imageretention" })
              ?.value ?? 0,
          lprTextRetention:
            _.find(entity?.settings ?? [], { name: "lpr.textretention" })
              ?.value ?? 0,
          lprMostRecentMatch:
            _.find(entity?.settings ?? [], { name: "lpr.mostrecentmatch" })?.value ===
              "true" ?? false,
          ticketmastermacaddress:
            _.find(entity?.settings ?? [], { name: "ticketmastermacaddress" })?.value ?? "",
          ticketmasterdeviceid:
            _.find(entity?.settings ?? [], { name: "ticketmasterdeviceid" })?.value ?? "",
          offlinefunctionalityenabled: castBool(_.find(entity?.settings ?? [], { name: "offlinefunctionalityenabled" })),
          exitofflinemessage:
            _.find(entity?.settings ?? [], { name: "exitofflinemessage" })
              ?.value ?? "",
          offlineticketexitfunction:
          _.find(entity?.settings ?? [], { name: "offlineticketexitfunction" })
            ?.value ?? 1,
          startOfDayWeekForReport:
          _.find(entity?.settings ?? [], { name: "startOfDayWeekForReport" })
            ?.value ?? "",
        };
      case c.ENTITY_TYPE.FacilityGroup:
        return {
          ccreplyto:
            _.find(entity?.settings ?? [], { name: "ccreplyto" })?.value ==
            "true",
          emailreply:
            _.find(entity?.settings ?? [], { name: "emailreply" })?.value ?? "",
          emaildisplayfrom:
            _.find(entity?.settings ?? [], { name: "emaildisplayfrom" })?.value ?? "",
          emailtemplate:
            _.find(entity?.settings ?? [], { name: "emailtemplate" })?.value ??
            "",
          valetareaticketlength:
            _.find(entity?.settings ?? [], { name: "valetareaticketlength" })?.value ?? 0
        };
      case c.ENTITY_TYPE.ValetArea:
        return {
          valetareaticketlength:
            _.find(entity?.settings ?? [], { name: "valetareaticketlength" })?.value ?? 0,
        }; 
    }
  };

  const commonSettings = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityId) ?? {};
    return extractCommonSettings(entity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  const commonSettingsParent = useSelector((state) => {
    const parentOfEntity = FindEntity(state.entities?.EntityList ?? [],entity.parententityid);
    return extractCommonSettings(parentOfEntity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  const typeSpecificSettings = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityId) ?? {};
    return extractTypeSpecificSettings(entity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  const typeSpecificSettingsParent = useSelector((state) => {
    const parentOfEntity =
      FindEntity(state.entities?.EntityList ?? [], entity.parententityid) ?? {};
    return extractTypeSpecificSettings(parentOfEntity);
    }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  const EditDeviceDemographicsPermission = hasPermissions(
    ["overview.devices.editdemographics"],
    false,
    entityId
  );
  const EditFacilityDemographicsPermission = hasPermissions(
    ["overview.facilities.editdemographics"],
    false,
    entityId
  );
  const EditValetAreaDemographicsPermission = hasPermissions(
    ["overview.valetarea.editdemographics"],
    false,
    entityId
  );
  const addAreaPermission = hasPermissions(
    ["overview.area.addarea"],
    false,
    entityId
  );
  const EditSettingsPermission = () => {
    switch (entity.typeid) {
      case c.ENTITY_TYPE.FacilityGroup:
        // Might need to replace with permissions for FG in later phase
        // Currently no permissions for FG.
        return hasPermissions(
          [
            "overview.facilities.editsettings"
          ],
          false,
          entityId
        );

      case c.ENTITY_TYPE.Facility:
        return hasPermissions(
          [
            "overview.facilities.editsettings"
          ],
          false,
          entityId
        );

      case c.ENTITY_TYPE.Device:
        return hasPermissions(
          [
            "overview.devices.editsettings"
          ],
          false,
          entityId
        );
      case c.ENTITY_TYPE.ValetArea:
        return hasPermissions(
          [
            "overview.valetarea.editsettings"
          ],
          false,
          entityId
        );
    }
  };

  const toggleShowEditor = () => {
    setShowEditDrawer(!showEditDrawer);
  };

  const closeDrawer = () => {
    setShowEditDrawer(false);
  };

  const closeDrawerAndExit = () => {
    setShowEditDrawer(false);
  };

  const drawerContent = () => {
    if (entity.typeid == c.ENTITY_TYPE.Facility) {
      return (
        <div style={{ padding: 10 }}>
          <FacilityEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.ValetArea) {
      return (
        <div style={{ padding: 10 }}>
          <ValetAreaEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.FacilityGroup) {
      return (
        <div style={{ padding: 10 }}>
          <FacilityGroupEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.Area) {
      return (
        <div style={{ padding: 10 }}>
          <AreaEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onSubmitComplete={closeDrawer}
            onCancel={closeDrawer}
          />
        </div>
      );
    } else {
      return (
        <>
          <GenericEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSaveComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (entity?.typeid === c.ENTITY_TYPE.Device) {
      dispatch(fetchPeripheralsList(entityId));
    }
  }, [entityId, entity.typeid, dispatch]);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          className={classes.root}
          style={{ paddingTop: 10, marginBottom: 10, width: "100%" }}
        >
          <Grid item xs={10} className={classes.gridItem}>
            <Typography
              className={classes.titleheading}
              variant="h5"
              color="primary"
              component="h2"
            >
              {entity.name}
            </Typography>
            <Typography
              className={classes.titlesubheading}
              variant="h6"
              color="secondary"
              component="h2"
            >
              {entity.description}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {((entity.typeid === c.ENTITY_TYPE.Device &&
              EditDeviceDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.Facility &&
                EditFacilityDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.ValetArea &&
                EditValetAreaDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.FacilityGroup &&
                currentUser.isAdmin) ||
              (entity.typeid === c.ENTITY_TYPE.Area &&
                addAreaPermission)) && (
              <Button
                className={clsx(["btn-edit"])}
                data-name={entity.name}
                size="small"
                variant={isSmallScreen ? "text" : "outlined"}
                onClick={toggleShowEditor}
                startIcon={<EditIcon />}
                color="primary"
              >
                {isSmallScreen ? "" : "EDIT"}
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 20 }} />

        {EditSettingsPermission && (
          <FlagFormContextProvider entityId={entityId}>
            <SettingsForm
              entityID={entityId}
              entityType={entity.type}
              settingValues={{ ...commonSettings, ...typeSpecificSettings }}
              settingValuesParent={{ ...commonSettingsParent, ...typeSpecificSettingsParent }}
              parentEntityID={entity?.parententityid}
            />
          </FlagFormContextProvider>
        )}
      </div>

      <Drawer
        className={classes.editorDrawer}
        anchor="right"
        open={showEditDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerContent(entity.typeid)}
      </Drawer>
    </>
  );
};

EntityDetailSettings.defaultProps = {
  entityId: undefined,
};
EntityDetailSettings.propTypes = {
  entityId: PropTypes.string,
};

export default EntityDetailSettings;
