import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  filterRoot: {
    display: "inline-flex",
    width: "100%",
  },
  credentialTypeDropdown: { 
    minWidth: "200px",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    }
  },
  activityTypeDropdown: {
    minWidth: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingRight: theme.spacing(2),
    },
  },
  dateFilterContainer: {
    display: "inline-flex",
    justifyContent: "flex-end",
  },
  dateArrowContainer: {
    display: "block",
  },
  activityTypeContainer: {
    display: "inline-flex",
    minWidth: "200px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    }
  },
  datePicker: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  primaryChipContainer: {
    display: "inline-flex",
    margin: theme.spacing(2),
    "& div": {
      marginRight: theme.spacing(2),
      minWidth: "50px",
    },
  },
  clearFilterBtn: {
    margin: theme.spacing(2),
  },
  title: {
    paddingTop: theme.spacing(1)
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2)
    }
  }
}));
