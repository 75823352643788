import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: { color: theme.palette.primary.main },
  subheader: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h1,
  },
	titlesubheading: {
		color: theme.palette.text.secondary,
		fontSize: 12,
		alignSelf: "center",
		paddingLeft: theme.spacing(.5)
	},
  root: {
    denomRow: {
      borderTopWidth: "0px",
      paddingTop: "4px",
      paddingBottom: "4px",
      borderTop: "0px solid white",
    },
  },
  table: {
    padding: "10%",
    height: "50%",
    width: "50%",
    border: "1px solid black",
  },
  tablecontainer: {
    padding: "100px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    borderBottomWidth: "0px",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  border: {
    color: "black",
  },
}))(TableCell);
