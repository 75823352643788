import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@material-ui/core";
import _ from "lodash";
import clsx from "clsx";

import { useStyles } from "./DevicesSelect.styles";
import useContextDevices  from "../../../hooks/useContextDevices";

export const DevicesSelect = ({ onSelect, value }) => {
    
  const classes   = useStyles();
  const facilityDevices = useContextDevices(false);
  const disabledDevicesList = useSelector((state) =>
    _.orderBy(state.callcenter.Queue, ["callStart"], ["asc"]),
    shallowEqual
  );    

  const defaultLabel = "Select a device";
  const [labelContent, setLabelContent] = useState(defaultLabel);
  const handleSelection = (event) => {
    var facility = _.find(facilityDevices, { devices: [ { entityid: event.target.value } ] });
    setLabelContent(facility.name ?? defaultLabel);
    onSelect(event);
  }
  
  const buildMenuOptions = () => {
    if (_.isNil(facilityDevices)) {
      return [];
    }

    const addFacilityDivider = facilityDevices.length > 1;
    let followUpFacility = false;
    const output = [];
    for (let facility of facilityDevices) {
      if (!facility.devices || facility.devices.length <= 0) {
        continue;
      }

      if (addFacilityDivider) {
        output.push(
          <ListSubheader
            key={`${facility.id}_subheader`}
            className={clsx(
              classes.facilityDivider,
              followUpFacility ? classes.facilityDividerSpacing : ""
            )}
            // clicking subheader initiates a select action
            // https://github.com/mui/material-ui/issues/18200
            // TODO: this might be messing with accessability/keyboard navigation FYI
            onClickCapture={(e) => e.stopPropagation()}
          >
            {facility.name}
          </ListSubheader>
        );
        followUpFacility = true;
      }

      facility.devices.forEach((device) => {
        output.push(
          <MenuItem
            className={addFacilityDivider ? classes.facilityDevice : ""}
            value={device.entityid}
            key={`device_${device.entityid}`}
            data-facility-name={facility.name}
            data-facility-id={facility.id}
            data-name={device.name}
            data-id={device.entityid}
            disabled={
              _.find(disabledDevicesList, {
                deviceID: device.entityid,
              }) != null
            }
          >
            {device.name}
          </MenuItem>
        );
      });
    }
    return output;
  };

  return (
    <FormControl variant="outlined">
      <InputLabel
        className={classes.selectLabel}
        htmlFor="grouped-native-select"
      >
        {labelContent}
      </InputLabel>
      <Select
        id="group-native-select"
        displayEmpty
        classes={{ select: classes.select }}
        value={value}
        onChange={handleSelection}
        inputProps={{
          name: "device-list",
          id: "device-list",
        }}
      >
        {buildMenuOptions()}
      </Select>
    </FormControl>
  );
};

export default DevicesSelect;
