import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiClient from "../../../../auth/apiClient";
import EnableThirdParties from "../../../Forms/Features/EnableThirdParties/index.js";
import EnableFeatureFlags from "../../../Forms/Features/EnableFeatureFlags/index.js";
import { FlagFormContextProvider } from "../../../Forms/Features/EnableFeatureFlags/flagformcontextprovider";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import ThirdpartyVendorService from "../../../../services/ThirdpartyVendorService";
import { ENTITY_TYPE } from "../../../../constants";
import _ from "lodash";

const thirdPartyVendorService = new ThirdpartyVendorService(apiClient);

export const EntityDetailFeatures = ({ entityId, entityTypeId }) => {
	const isFacility = entityTypeId === ENTITY_TYPE.Facility;
    const isValetArea = entityTypeId === ENTITY_TYPE.ValetArea;
	const isAdmin = useSelector((state) => state?.user?.isAdmin);

  const [subscriberPermissions, setSubscriberPermissions] = useState([]);
  const [subscribers, setSubscribers] = useState([]);

  const getThirdPartyInfo = useCallback(async () => {
    try {
      const response = await thirdPartyVendorService.getThirdParty(
        null,
        entityId,
        null
      );
      setSubscribers(_.orderBy(response.data,"[description]","asc")); //3rd party integrators, list them alpha
    } catch (err) {
      console.error(err);
    }
  }, [entityId]);
  useEffect(() => {
    if (entityId) {
      getThirdPartyInfo();
    }
  }, [entityId, getThirdPartyInfo]);

  const enqueueSnackbar = useEnqueueSnackbar();
  const getSubscriberPermissions = useCallback(async () => {
    thirdPartyVendorService
      .getSubscriberPermissions()
      .then((res) => {
        if (res.status === 200) {
          setSubscriberPermissions(res.data);
        } else {
          enqueueSnackbar(
            "We encountered a problem retrieving subscriber permissions",
            { variant: "error", tag: "ErrorRetrievingSubscriberPermissions" }
          );
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to retrieve subscriber permissions", {
          variant: "error",
          tag: "FailedToRetrieveSubscriberPermissions",
        });
      });
  }, []);

  useEffect(() => {
    getSubscriberPermissions();
  }, [getSubscriberPermissions]);

  return (
		<>
			{
				//Change this when we CI feature flagging
				isAdmin && (isFacility || isValetArea) && (
				<FlagFormContextProvider entityId={entityId}>
					<EnableFeatureFlags />
				</FlagFormContextProvider>
			)}
			{
			isFacility && (
				<EnableThirdParties
					entityID={entityId}
					subscriberPermissions={subscriberPermissions}
					subscribers={subscribers}
					/>
			)}
    </>
  ); 
};

EntityDetailFeatures.defaultProps = {
  entityId: undefined,
  entityTypeId: undefined,
};
EntityDetailFeatures.propTypes = {
  entityId: PropTypes.string,
  entityTypeId: PropTypes.number,
};

export default EntityDetailFeatures;
