import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import SquareChip from "../../SquareChip";
import useContractContext from "../../../hooks/useContractContext";
import {
  clearContractFilters,
  onFiltersChanged,
} from "../../../reducers/contracts/contractReducer";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import {
  Checkbox,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ArrowUpwardRounded, ArrowDownwardRounded, FilterNoneRounded } from '@material-ui/icons';

const ASC = "ASC";
const DESC = "DESC";
const NONE = "NONE";
export const SORT_DIRECTION = { ASC, DESC, NONE };

const GroupContractFilters = () => {
  const classes = useStyles();
  const [values, dispatch] = useContractContext();
  const [nameSortDirection, setNameSortDirection] = useState(SORT_DIRECTION.NONE);
  const [acctNumberSortDirection, setAcctNumberSortDirection] = useState(SORT_DIRECTION.NONE);

  const handleNameChange = (value) => {
    setNameSortDirection(value);
    dispatch({ type: onFiltersChanged, payload: { nameSortDirection: value } });
  };

  const handleAccountNumberChange = (value) => {
    setAcctNumberSortDirection(value);
    dispatch({ type: onFiltersChanged, payload: { acctnumberSortDirection: value } });
  };

  const handleClearFilters = () => {
    setNameSortDirection(SORT_DIRECTION.NONE);
    setAcctNumberSortDirection(SORT_DIRECTION.NONE);
    dispatch({ type: clearContractFilters });
  };

  return (
    <>
      <Grid container className={classes.filterRoot}>
        <Grid item sm={2} md={4} className={clsx("contract-name-container")}>
          <Grid container className={clsx("contract-name-container", classes.contractNameContainer)}>
            <Grid item sm={6} md={4}>
              <Typography>Contract Name: </Typography>
            </Grid>
            <Grid item sm={6} md={8}>
              <ToggleButtonGroup
                className={clsx("acct-number-sort-group", classes.contractNameSortGroup)}
                value={nameSortDirection}
                exclusive
                size="small"
                onChange={(event, newValue) => {
                  handleNameChange(newValue);
                }}
                aria-label="text contract name sort"
              >
                <ToggleButton value={SORT_DIRECTION.NONE} aria-label="none">
                  <FilterNoneRounded />
                </ToggleButton>
                <ToggleButton value={SORT_DIRECTION.ASC} aria-label="asc">
                  <ArrowUpwardRounded />
                </ToggleButton>
                <ToggleButton value={SORT_DIRECTION.DESC} aria-label="desc">
                  <ArrowDownwardRounded />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={2} md={4} className={clsx("account-number")}>
          <Grid container className={clsx("account-number-container", classes.accountNumberContainer)}>
            <Grid item sm={6}>
              <Typography>Account Number: </Typography>
            </Grid>
            <Grid item sm={6}>
              <ToggleButtonGroup
                className={clsx("acct-number-sort-group", classes.acctNumberSortGroup)}
                value={acctNumberSortDirection}
                exclusive
                size="small"
                onChange={(event, newValue) => {
                  handleAccountNumberChange(newValue);
                }}
                aria-label="text acct number"
              >
                <ToggleButton value={SORT_DIRECTION.NONE} aria-label="none">
                  <FilterNoneRounded />
                </ToggleButton>
                <ToggleButton value={SORT_DIRECTION.ASC} aria-label="asc">
                  <ArrowUpwardRounded />
                </ToggleButton>
                <ToggleButton value={SORT_DIRECTION.DESC} aria-label="desc">
                  <ArrowDownwardRounded />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={2} md={4} className={clsx("clear-sort-container", classes.clearFiltersContainer)}>
          <SquareChip
            className={clsx(classes.clearFilterBtn, "clear-Sorting")}
            data-id="clear-Sorting"
            text="Clear Sorting"
            onClick={handleClearFilters}
            mode="red"
          />
        </Grid>
      </Grid>
    </>
  );
};

GroupContractFilters.defaultProps = {
};

GroupContractFilters.propTypes = {
};

export default GroupContractFilters;