import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {

    },
    offline: {
        color: theme.palette.text.disabled
    }
   

}));