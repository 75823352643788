import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {

    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    low: {
        color: theme.palette.warning.light,
    },
    critical:{
        color: theme.palette.secondary.dark,
    },
    warning: {
        color: theme.palette.warning.dark,

    },
    normal: {
        color:theme.palette.text.secondary,
    },
    info: {
        color: theme.palette.info.dark,
    },
}));