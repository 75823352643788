import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCity,
  faLayerGroup,
  faTablet,
  faWarehouseFull as FacilityGroupIcon,
} from "@fortawesome/pro-regular-svg-icons";
import { Button, Divider, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useEntityData } from "../../../providers/EntityProvider";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFlags } from "launchdarkly-react-client-sdk";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const AddEntity = ({ entityID, selectionCallback }) => {
  const theme = useTheme();
  const { functions } = useEntityData(); //entityprovider
  const { getAllowableChildren } = functions; //entityprovider

  const [allowedChildren, setAllowedChildren] = useState([]);

  const currentUser = useCurrentUser();
  const { hasPermissions } = useHasPermissions();
  const addDevicePermission = hasPermissions(["overview.devices.adddevice"]);
  const addAreaPermission = hasPermissions(["overview.area.addarea"]);
  
  const { facilityGroups, valetArea, contractNest } = useFlags();
  const contractNestFeature = useFeatureFlag("Contract Nest");

  useEffect(() => {
    //only need it once, entity object will never change in a way that this needs to be called again
    getAllowableChildren(entityID).then((x) => {
      setAllowedChildren(x.data);
    });
  }, [entityID]);

  const canAddType = (type) => {
    switch (type) {
      case "Device":
        return addDevicePermission;
      case "FacilityGroup":
        return facilityGroups && currentUser.isAdmin;
      case "ValetArea":
        return valetArea && currentUser.isAdmin;
      case "Area":
        return contractNestFeature && contractNest && addAreaPermission;
      case "Lane":
        return false; // feature disabled for now
      default:
        return true;
    }
  };

  return (
    <>
      <div
        style={{ minWidth: 300, padding: 15 }}
        className={clsx("add-features")}
      >
        <Typography variant="h5" color="primary" component="h2">
          Adding Features
        </Typography>
        <Divider
          role="presentation"
          style={{ marginBottom: 15, marginTop: 10 }}
        ></Divider>
        {allowedChildren.map(function(a, i) {
          return (
            <>
              {canAddType(a) && (
                <div key={i}>
                  <div style={{ clear: "both" }}>
                    <span style={{ float: "left" }}>
                      <FontAwesomeIcon
                        style={{ color: theme.palette.primary.main }}
                        icon={
                          {
                            Facility: faCity,
                            ValetArea: faCity,
                            Area: faLayerGroup,
                            FacilityGroup: FacilityGroupIcon,
                          }[a] || faTablet
                        }
                        size="2x"
                      ></FontAwesomeIcon>
                    </span>
                    <Button
                      style={{ float: "right" }}
                      startIcon={<AddIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => selectionCallback(a)}
                      className={clsx("add", `add-${a}`)}
                    >
                      Add{" "}
                      {{
                        FacilityGroup: "Facility Group",
                        ValetArea: "Valet Area",
                      }[a] || a}
                    </Button>
                  </div>
                  <div
                    style={{
                      clear: "both",
                      marginTop: 15,
                      paddingTop: 20,
                      color: theme.palette.text.secondary,
                    }}
                  >
                    <div>
                      {a == "Device" && (
                        <div style={{ maxWidth: 300 }}>
                          Add a device to a facility. This will create the
                          profile for the device and display a QR code. Show the
                          QR code to the device camera to complete its
                          provisioning. You can redisplay the QR code as needed.
                        </div>
                      )}
                      {a == "Facility" && (
                        <div style={{ maxWidth: 300 }}>
                          Creating a facility allows you to start adding devices
                          to the facility.
                        </div>
                      )}
                      {a == "FacilityGroup" && (
                        <div style={{ maxWidth: 300 }}>
                          Group facilities together for easy management of
                          features including access holders, dashboard
                          activities, and reporting.
                        </div>
                      )}
                      {a == "Area" && (
                        <div style={{ maxWidth: 300 }}>
                          Add a nested area to your facility.
                        </div>
                      )}
                      {a == "ValetArea" && (
                        <div style={{ maxWidth: 300 }}>
                          Creating a valet area allows you to start adding devices 
                          to the valet area.
                        </div>
                      )}                      
                    </div>
                  </div>
                  <Divider
                    role="presentation"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  ></Divider>
                </div>
              )}
            </>
          );
        })}
      </div>
    </>
  );
};

AddEntity.defaultProps = { entityID: null };
AddEntity.propTypes = { entityID: PropTypes.string };

export default AddEntity;
