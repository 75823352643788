import React, { useState } from "react";
import propTypes from "prop-types";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import {
	faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";
import clsx from "clsx";

const FileDownloadButton = ({
	text,
	downloadingText,
  color,
  entityId,
  entityDate,
  downloadHandler,
  fileName,
  fileType,
  onDownloadError,
  ...props
}) => {
	const classes = useStyles();
	const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
		setIsDownloading(true);
    await downloadHandler()
      .then(({ data }) => {
        if (data) {
          const url = window.URL.createObjectURL(
            new Blob([data], { type: `application/${fileType}` })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.${fileType}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }

				setIsDownloading(false);
      })
      .catch(err => {
        if (onDownloadError) {
          onDownloadError(err);
				}
				setIsDownloading(false);
      });
  };

  return (
    <Button
      className={clsx(['download-button',classes.button, entityId])}
      color={color}
      data-id={entityId}
      data-date={entityDate}
      variant="contained"
      type="submit"
      onClick={handleDownload}
      size={props.size}
			startIcon={isDownloading ?
				<FontAwesomeIcon spin icon={faSpinnerThird} /> :
				<FontAwesomeIcon icon={faDownload} />
			}
			disabled={isDownloading}
    >
			{isDownloading ? downloadingText : text}
    </Button>
  );
};

FileDownloadButton.defaultProps = {
  color: "primary"
};

FileDownloadButton.propTypes = {
	text: propTypes.string,
	downloadingText: propTypes.string,
  color: propTypes.string,
  entityId: propTypes.string,
  entityDate: propTypes.string,
  downloadHandler: propTypes.func.isRequired,
  fileName: propTypes.string.isRequired,
  fileType: propTypes.oneOf(["pdf", "xlsx", "png"]),
  onDownloadError: propTypes.func
};

export default FileDownloadButton;
