import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import SingleSelectDropdown from "../../Dropdowns/SingleSelect";
import useContractContext from "../../../hooks/useContractContext";
import { onFiltersChanged } from "../../../reducers/contracts/contractReducer";
import { Grid, Divider } from "@material-ui/core";
import clsx from "clsx";

const AccessHolderFilters = ({ groups }) => {
  const classes = useStyles();
  const [values, dispatch] = useContractContext();
  const [showClearIcon, setShowClearIcon] = useState();

  const credentials = [{ name: "No Credentials", value: "none" }];

  const statuses = [
    { name: "IN", value: 1 },
    { name: "OUT", value: 2 },
    { name: "NEUTRAL", value: 3 },
  ];
  const modes = [
    { name: "Normal", value: 1 },
    { name: "Locked", value: 2 },
    { name: "Override", value: 3 },
    { name: "Override AP", value: 4 },
    { name: "Inactive", value: 5 },
  ];

  useEffect(() => {
    setShowClearIcon(true);
  }, []);

  const handleCredentialTypeSelect = (value) => {
    dispatch({ type: onFiltersChanged, payload: { credentialType: value, accessHolderPage: 1 } });
  };

  const handleStatusChange = (value) => {
    dispatch({ type: onFiltersChanged, payload: { status: value, accessHolderPage: 1 } });
  };

  const handleModeChange = (value) => {
    dispatch({ type: onFiltersChanged, payload: { mode: value, accessHolderPage: 1 } });
  };

  const handleGroupChange = (value) => {
    dispatch({ type: onFiltersChanged, payload: { group: value, accessHolderPage: 1 } });
  };

  return (
    <>
      <Grid container className={classes.filterRoot}>
        <Grid item className={classes.title}>
          <p>Filters</p>
        </Grid>
        <Grid item xs={12} md={2}>
          <SingleSelectDropdown
            name="Credential Type"
            className={clsx(
              classes.credentialTypeDropdown,
              "credentialType-dropdown"
            )}
            options={credentials}
            onSelect={handleCredentialTypeSelect}
            value={values.credentialType ?? ""}
            showClearIcon={showClearIcon}
          />
        </Grid>

        <Grid item xs={12} md={2} className={classes.activityTypeContainer}>
          <SingleSelectDropdown
            name="Status"
            className={clsx(classes.activityTypeDropdown, "status-dropdown")}
            options={statuses}
            onSelect={handleStatusChange}
            value={values.status ?? ""}
            showClearIcon={showClearIcon}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.activityTypeContainer}>
          <SingleSelectDropdown
            name="Mode"
            className={clsx(classes.activityTypeDropdown, "mode-dropdown")}
            options={modes}
            onSelect={handleModeChange}
            value={values.mode ?? ""}
            showClearIcon={showClearIcon}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.activityTypeContainer}>
          <SingleSelectDropdown
            name="Access Group"
            className={clsx(
              classes.activityTypeDropdown,
              "accessGroup-dropdown"
            )}
            options={groups}
            onSelect={handleGroupChange}
            value={values.group ?? ""}
            showClearIcon={showClearIcon}
          />
        </Grid>
      </Grid>
      <Divider  className={clsx(classes.divider)} />
    </>
  );
};

AccessHolderFilters.defaultProps = {
  onChange: () => { },
  selectedStatus: "unselected",
};

AccessHolderFilters.propTypes = {
  onChange: PropTypes.func,
  selectedStatus: PropTypes.string,
  selectedCredentialType: PropTypes.string,
};

export default AccessHolderFilters;
