import React from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEngineWarning } from "@fortawesome/pro-duotone-svg-icons";
import { Typography, Button, Zoom } from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import { FindEntity } from "../../../state/slices/entities";
import * as c from "../../../constants";
import _ from "lodash";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useHubContext from "../../../hooks/useHubContext";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";

const EntityRebootButton = ({ entityID, ...props }) => {
  const theme = useTheme();
  const entity = useSelector((state) => {
    const entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      armingLoopOn: entity?.state?.Peripherals?.Gate?.ArmingLoop == 1,
      closingLoopOn: entity?.state?.Peripherals?.Gate?.ClosingLoop == 1,
      name: entity?.name,
      entityID: entity?.entityid,
      disabled: entity?.state == null,
      transactionInProgress: entity?.state?.transactionInProgress === true,
    };
  }, shallowEqual);
  const currentUser = useSelector((state) => state.user, shallowEqual);

  const enqueueSnackbar = useEnqueueSnackbar();
  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const { showDialog } = useConfirmationDialog();

  const handleClick = async () => {
    const msgComponent = (
      <>
        {entity.armingLoopOn || entity.closingLoopOn ? (
          <>
            <FontAwesomeIcon
              icon={faEngineWarning}
              size="4x"
              className={clsx(["animate__animated", "animate__tada"])}
              style={{
                color: theme.palette.error.main,
                float: "left",
                alignItems: "baseline",
                marginRight: 15,
              }}
            ></FontAwesomeIcon>
            <div style={{ color: theme.palette.error.main }}>
              Device is reporting a vehicle in the lane. The device will be out
              of operation until reboot is complete. Are you sure?
            </div>
          </>
        ) : (
          <>
              {entity.transactionInProgress? (
                <>
                  <FontAwesomeIcon
                    icon={faEngineWarning}
                    size="4x"
                    className={clsx(["animate__animated", "animate__tada"])}
                    style={{
                      color: theme.palette.error.main,
                      float: "left",
                      alignItems: "baseline",
                      marginRight: 15,
                    }}
                  ></FontAwesomeIcon>
                  <div style={{ color: theme.palette.error.main }}>
                    Device is reporting a transaction is in progress. The device will be
                    out of operation until reboot is complete. Are you sure?
                  </div>
                </>
              ) : (
                <Typography>
                  The device will be out of operation until the reboot process is
                  complete.
                </Typography>
              )}
          </>
        )}
      </>
    );
    const continueReboot = await showDialog({
      title: `REBOOT ${entity.name.toUpperCase()}?`,
      message: msgComponent,
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled },
      ],
    });

    if (continueReboot === "Yes") {
      portalHub.invoke(c.PORTAL_TRIGGER, {
        entityID: entityID,
        topic: "REBOOT",
        method: "rebootdevice",
        action: "reboot",
        message: JSON.stringify({
          user: currentUser.Username,
          timeOfChange: Date.now(),
        }),
      });
      enqueueSnackbar(`Command sent to ${entity.name} to reboot the device.`, {
        variant: "info",
        TransitionComponent: Zoom,
      });
    }
  };

  return (
    <Button
      disabled={entity.disabled || !PortalHubConnected}
      {...props}
      onClick={handleClick}
    >
      REBOOT DEVICE
    </Button>
  );
};

EntityRebootButton.defaultProps = { entityID: null };
EntityRebootButton.propTypes = { entityID: PropTypes.string };

export default EntityRebootButton;
