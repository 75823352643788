import clsx from "clsx";
import React, { useState } from "react";
import { useStyles } from "./styles";
import SingleSelectDropdown from "../../Dropdowns/SingleSelect";
import SquareChip from "../../SquareChip";
import PropTypes from "prop-types";

const AccessGroupFilters = ({
  groupTypeFilter,
  onGroupTypeChange,
  onFilterClear
}) => {
  const classes = useStyles();
  const [groupType, setGroupType] = useState(groupTypeFilter);

  const accessgrouptypeschoices = [
    { name: "Normal", value: 1 },
    { name: "Flexible Parking Account", value: 2 }
  ];

  const handleAccessGroupTypeSelect = (value) => {
    setGroupType(value);
    onGroupTypeChange(value);
  };

  const handleFilterClear = () => {
    setGroupType("");
    onFilterClear();
  };

  return (
    <div className={classes.filterRoot}>
      <div className={classes.groupTypeContainer}>
        <SingleSelectDropdown
          name="Access Group Type"
          className={clsx(classes.groupTypeDropdown, "groupType-dropdown")}
          options={accessgrouptypeschoices}
          onSelect={handleAccessGroupTypeSelect}
          value={groupType ?? ""}
        />
      </div>
      <div>
        <SquareChip
          className={clsx(classes.clearFilterBtn, "clear-filters")}
          data-id="clear-filters"
          text="Clear Filters"
          onClick={handleFilterClear}
          mode="red"
        />
      </div>
    </div>
  );
};

AccessGroupFilters.defaultProps = {
  groupTypeFilter: "",
  onGroupTypeChange: () => { },
  onFilterClear: () => { }
};

AccessGroupFilters.propTypes = {
  groupTypeFilter: PropTypes.string,
  onGroupTypeChange: PropTypes.func,
  onFilterClear: PropTypes.func
};

export default AccessGroupFilters;