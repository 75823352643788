import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  textEnd: {
    textAlign: "end",
  },
  textBoxEnd: {
    justifyContent: "end",
    alignSelf: "flexEnd",
  },
  value: {
    color: theme.palette.primary?.main,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  paddingLeft: {
    paddingLeft: "1rem",
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  paperContainerLarge: {
    padding: "1.5rem 1rem",
  },
  paperContainer: {
    padding: "1rem",
  },
  spacing: {
    padding: "1rem 1rem 1rem 1rem",
  },
  errorContainer: {
    color: theme.palette.grey?.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    textAlign: "center",
  },
  receiptButton: { marginTop: "1rem" },
  timelineDetails: {
    display: "flex",
    justifyItems: "center",
    gap: "0.25rem",
    flexDirection: "row-reverse",
  },
  invalid: {
    color: "red",
    textAlign: "center",
  },
  accessHolder: {
    color: theme.palette.primary?.main,
    fontWeight: "bold",
  },
  termsAndConditions: { paddingBottom: "1rem" },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  mainWidth: {
    maxWidth: "800px",
  },
  muiGridRoot: {
    flexGrow: "1"
  },
  spaceOnTop: { marginTop: "1rem" },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
}));
