import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Clear, ExpandMore } from "@material-ui/icons";

const SingleSelectDropdown = ({
  className,
  name,
  options,
  onSelect,
  handleOpen,
  handleClose,
  defaultSelected,
  variant,
  value,
  fullWidth,
  ["data-id"]: dataID,
  onClearClick,
  showClearIcon,
  ...props
}) => {
  const classes = useStyles();
  const thisAttributeName = name.replace(/\s/g, "");

  const [displayEmpty, setDisplayEmpty] = useState();
  useEffect(() => {
    if (props.displayEmpty) setDisplayEmpty(props.displayEmpty);
  }, [props.displayEmpty]);

  const handleChange = e => {
    onSelect(e.target?.value);
  };

  return (
    <FormControl
      className={clsx(classes.container, className)}
      error={props.error}
      fullWidth={fullWidth}
    >
      <InputLabel id="label" shrink={displayEmpty} className={classes.label}>
        {name}
      </InputLabel>
      <Select
        labelId="label"
        data-id={dataID ?? "single-select-dropdown"}
        className={clsx(`${thisAttributeName}-select-dropdown`, classes.select)}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        defaultValue={defaultSelected}
        variant={variant}
        value={value}
        IconComponent={showClearIcon && value ? "span" : ExpandMore}
        endAdornment={showClearIcon ? 
          <IconButton style={{"visibility": value ? "visible":"hidden", padding: "0px"}} onClick={() => {handleChange("")}}><Clear fontSize="small" /></IconButton> : <></>}
        {...props}
      >
        {options?.map(item => {
          return (
            <MenuItem
              data-id={`${item.name}`}
              key={item.value}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

SingleSelectDropdown.defaultProps = {
  onSelect: () => {},
  onClearClick: () => {},
  variant: "standard",
};

SingleSelectDropdown.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      name: PropTypes.string,
    })
  ),
  onSelect: PropTypes.func,
  onClearClick: PropTypes.func,
  defaultSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SingleSelectDropdown;
