import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVITY_TYPES, OCCUPANCY_TYPES } from "../../../constants";
import {
  clearActivityFilters,
  setActivityType,
  setCredentialType
} from "../../../state/slices/filters/activity";
import SingleSelectDropdown from "../../Dropdowns/SingleSelect";
import SquareChip from "../../SquareChip";
import { useStyles } from "./styles";
import React from "react";

const ActivityFilters = ({ onChange, ...props }) => {
  const classes = useStyles();
  const activityFilters = useSelector(state => state.activity);
  const dispatch = useDispatch();
  const occupancyTypes = [
    { name: "All", value: "" },
    { name: "Transient", value: OCCUPANCY_TYPES.Transient },
    { name: "Contract", value: OCCUPANCY_TYPES.Contract }
  ];
  const activityTypes = [
    { name: "All", value: "" },
    { name: "Enter", value: ACTIVITY_TYPES.Enter },
    { name: "Exit", value: ACTIVITY_TYPES.Exit },
    { name: "Payment", value: ACTIVITY_TYPES.Payment },
    { name: "Pay On Entry", value: ACTIVITY_TYPES.PayOnEntry }
  ];

  const handleCredentialSelectChange = value => {
    dispatch(setCredentialType(value));
    onChange();
  };

  const handleActivitySelectChange = value => {
    dispatch(setActivityType(value));
    onChange();
  };

  const handleClearFilters = () => {
    dispatch(clearActivityFilters());
    onChange();
  };

  return (
    <>
      <div className={classes.filterRoot}>
        <div className={classes.activityTypeContainer}>
          <SingleSelectDropdown
            name="Credential Type"
            className={clsx(
              "credential-type-dropdown",
              classes.activityTypeDropdown
            )}
            options={occupancyTypes}
            onSelect={handleCredentialSelectChange}
            value={activityFilters.CredentialType ?? "unset"}
          />
        </div>
        <div className={classes.activityTypeContainer}>
          <SingleSelectDropdown
            name="Activity Type"
            className={clsx(
              "activity-type-dropdown",
              classes.activityTypeDropdown
            )}
            options={activityTypes}
            onSelect={handleActivitySelectChange}
            value={activityFilters.ActivityType ?? "unset"}
          />
        </div>
        <SquareChip
          className={clsx("clear-filters-button", classes.clearFilterBtn)}
          data-id="clear-filters"
          text="Clear Filters"
          onClick={handleClearFilters}
          mode="red"
        />
      </div>
    </>
  );
};

ActivityFilters.defaultProps = {
  onChange: () => {}
};

ActivityFilters.propTypes = {
  onChange: PropTypes.func
};

export default ActivityFilters;
