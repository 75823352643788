import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { useStyles } from "./ToiletSwirl.styles";
import { FindEntity } from "../../../state/slices/entities";
import { CardHeader } from "@material-ui/core";
import EntityAvatar from "./EntityAvatar";
import EntityErrorMessages from "./EntityErrorMessages";
import clsx from "clsx";
import _ from "lodash";

const EntityToiletSwirl = ({
  entityID,
  iconSize, 
  disableTitle,
  disabled,
  isBeingListened,
}) => {
  const classes = useStyles();
  const entity = useSelector((state) => {
    const _entity = FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      entityID: _entity?.entityid,
      type: _entity?.type,
      name: _entity?.name,
      typeID: _entity?.typeid,
      offline: _entity?.state === null ? true : false,
    };
  }, shallowEqual); //found entity within state store

  return (
    <CardHeader
      className={clsx([
        classes.root,
        entity.offline && entity.typeID === 3 ? classes.offline : "",
      ])}
      key={entityID}
      style={{ padding: 4, margin: 4 }}
      titleTypographyProps={{ variant: "h5" }}
      title={disableTitle ? "" : <span>{entity.name}</span>}
      avatar={
        <EntityAvatar
          entityID={entityID}
          iconSize="1x"
          disabled={disabled}
          isBeingListened={isBeingListened}
        />
      }
      subheader={
        entity.typeID === 3 && !disabled ? (
          <EntityErrorMessages
            entityID={entityID}
            isBeingListened={isBeingListened}
          />
        ) : (
          ""
        )
      }
      data-name={entity.name}
    ></CardHeader>
  );
};

EntityToiletSwirl.defaultProps = {
  entityID: null,
  iconSize: "1x",
  disableTitle: false,
  isBeingListened: true,
};
EntityToiletSwirl.propTypes = {
  entityID: PropTypes.string,
  iconSize: PropTypes.string,
  disableTitle: PropTypes.bool,
  isBeingListened: PropTypes.bool,
};

export default EntityToiletSwirl;
