import React, {useEffect, useState, useCallback} from "react";
import {Button, ListItemIcon, MenuItem, Tooltip, ListItemText} from "@material-ui/core";
import clsx from "clsx";
import * as c from "../../../constants";
import useHubContext from "../../../hooks/useHubContext";
import {useEnqueueSnackbar} from "../../../hooks/useEnqueueSnackbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRetweetAlt} from "@fortawesome/pro-duotone-svg-icons";
import PeripheralService from "../../../services/PeripheralService";
import apiClient from "../../../auth/apiClient";

const service = new PeripheralService(apiClient);

const CycleDoorButton = ({entityID, disabled, onClick}) => {
  const {portalHub} = useHubContext();
  const [doorStrikeIoData, setDoorStrikeIoData] = useState({});
  const [hasNoIoMapping, setHasNoIoMapping] = useState(true);
  const enqueueSnackbar = useEnqueueSnackbar();

  const GetDoorDataFromPeripherals = useCallback(async () => {
    try {
      const result = await service.getPeripherals(entityID);
      let doorData = result.data.find((x) => x.name.toLowerCase() === "doorstrike")?.settings;
      if (doorData === undefined) {
        return;
      }

      setDoorStrikeIoData(doorData);
      setHasNoIoMapping(doorData?.ioMappingID === undefined || doorData?.ioMappingID <= 0);
    } catch (error) {
      enqueueSnackbar("Failed to get door strike info from peripherals", {
        variant: "error",
        tag: "getDoorStrikeData",
      });
    }
  }, [entityID]);

  useEffect(() => {
    GetDoorDataFromPeripherals();
  }, [GetDoorDataFromPeripherals]);

  const handleCycleDoorClick = () => {
    try {
      portalHub.invoke(c.PORTAL_TRIGGER_C2E, {
        entityID: entityID,
        message: {
          name: "DOOR_STRIKE",
          payload: {
            ioMappingID: doorStrikeIoData?.ioMappingID,
            duration: parseInt(doorStrikeIoData?.vendStrikeDuration) ?? 0,
          },
        },
      });
      if (onClick) {
        onClick();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const ButtonText = ({disabled = false}) => (
    <span>
      <Button
        className={clsx("cycle-door-button")}
        size="small"
        color="primary"
        style={{backgroundColor: "transparent"}}
        disabled={disabled}
        data-testid="cycle-door-button"
      >
        Cycle Door
      </Button>
    </span>
  );

  const Icon = () => (
    <ListItemIcon style={{marginRight: -15}}>
      <FontAwesomeIcon className={clsx("cycle-door-icon")} icon={faRetweetAlt} swapOpacity/>
    </ListItemIcon>
  );

  return (
    <>
      {disabled || hasNoIoMapping ? (
        <Tooltip
          data-testid="cycle-door-tooltip"
          className={clsx("cycle-door-tooltip")}
          title={hasNoIoMapping ? "Please set your GPIO mapping first to enable this action." : "Door is unavailable"}
        >
          <MenuItem>
            <Icon/>
            <ButtonText disabled={true}/>
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem onClick={() => handleCycleDoorClick()}>
          <Icon/>
          <ButtonText/>
        </MenuItem>
      )}
    </>
  );
};

export default CycleDoorButton;
