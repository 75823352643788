import { makeStyles, lighten, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

  //elevate ribbon so statusBar is under it.
  ribbonator: {
    '&>div' :{zIndex:1200}, //a bunch, but under MUI drawer
  },

  card: {
    minWidth: 300,
    '&.disabled': {backgroundColor:theme.palette.action.selected},
  },

  //color,position of lot-full, lane closed on card
  closedMsg :{
    color : theme.palette.amano.closed.main,
    position:"absolute", 
    bottom:32, 
    right:6,
    fontWeight:"bold",
    '&.full':{
      bottom:48,
      color:theme.palette.warning.dark,
    }
  },

  ribbonDisabled: {backgroundColor:"#c0c0c0" ,color:"#fff" ,fontFamily:"Arial"},
  ribbonWarning : {backgroundColor:"#ffcc00" ,color:"#fff" ,fontFamily:"Arial"},
  ribbonClosed  : {backgroundColor:theme.palette.text.secondary, color:theme.palette.action.hover},


}));
