import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dropdown: {
    "& label": {
      textOverflow: "ellipsis !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  loadingIcon: {},
}));
